@import url('../../marketplace.css');

.root {
}

.header {
  color: var(--textLight);
  font-size: 20px;
  line-height: 1.5;
  font-weight: var(--fontWeightBold);
}

/* Offers table */
.offers {
  margin-bottom: 32px;
  width: 100%;
}
.offer {
  border: 1px solid var(--matterColorNegative);
  border-left: 0;
  border-right: 0;
  font-weight: var(--fontWeightRegular);
}
.offerPending {
  & .donation,
  & .name {
    font-weight: var(--fontWeightBold);
  }

  & .name::before {
    background-color: var(--failColor);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }
}
.offer td {
  font-size: 16px;
  padding: 12px 0;
}
.nameCol {
  width: 50%;
}
.donationCol {
  width: 15%;
}
.statusCol {
  width: 15%;
}
.viewsCol {
  width: 10%;
}
.controlsCol {
  text-align: right;
  width: 10%;
}

/* Offers table column labels */
.foot {
  color: var(--textLight);
  display: block;
  font-size: 12px;
  padding-right: 12px;
  line-height: 15px;
  padding-top: 5px;
}
.confirmed {
  color: var(--successColor);
  font-weight: var(--fontWeightMedium);
}
.discarded {
  color: var(--failColor);
  font-weight: var(--fontWeightMedium);
}
.archived {
  color: var(--textLight);
  font-weight: var(--fontWeightMedium);
}

/* Offer menu */
.menuContent {
  padding: 18px 0;
  width: 220px;
}
.menuLabel {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
  width: auto;
}
.menuItem {
  background-position: 18px center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1;
  padding: 8px 16px 8px 42px;
  text-align: left;
}

.menuItem:hover {
  background-color: #fac51d;
}

.menuItemCopy {
  background-image: var(--iconCopy);
}
.menuItemDetails {
  background-image: var(--iconInfo);
}
.menuItemRemove {
  background-image: var(--iconTrash);
}
.menuItemArchive {
  background-image: var(--iconArchive);
}
.controls {
  display: flex;
  flex-direction: row;
}
.button {
  padding-left: 0;

  &:first-child {
    margin-right: 4px;
  }

  &:last-child {
    margin-left: 4px;
  }
}

.bookerMessageIcon {
  background: var(--iconMessage) center center no-repeat;
  display: inline-block;
  height: 16px;
  margin-bottom: -4px;
  margin-left: 6px;
  width: 16px;
}

.tippy {
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
}

.tippy:after,
.tippy:before {
  background-color: white;
}

.svgTip {
  margin-left: 6px;
  margin-bottom: 3px;
}

.tippyHeader {
  margin: 0 0 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #1c1a34;
}

.tippyIngress {
  margin: 12px 0 0;
  font-size: 14px;
  line-height: 1.29;
  color: #1c1a34;
}

.approved {
  background-color: var(--successColor);
  padding: 2px 4px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  margin-left: 8px;
  vertical-align: top;
}
