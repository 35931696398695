@import '../../../../marketplace.css';

.root {
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 840px;
  padding: 0 24px 24px 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--textDark);
  font-size: 32px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.5px;
  line-height: 1.13;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 48px;
    letter-spacing: -1px;
    line-height: 1.17;
    text-align: center;
    white-space: pre-line;
  }
}

.subTitle {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  line-height: 1.6;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: var(--fontWeightMedium);
    letter-spacing: -0.3px;
    line-height: 1.17;
    text-align: center;
  }
}

.text {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  margin-bottom: 0;

  @media (--viewportMedium) {
    text-align: center;
  }
}

.actions {
  text-align: center;
}

.button {
  @apply --marketplaceButtonStyles;
  border-radius: 4px;
  color: var(--textDark);
  display: inline-block;
  line-height: 65px;
  margin-top: 34px;
  padding: 0;
  vertical-align: middle;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0 80px;
    width: auto;
  }
}
