@import url('../../marketplace.css');

.root {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1056px;
  }
}

.card {
  background: var(--white);
  box-shadow: var(--boxShadowListingCard);
  margin: 0 24px 12px;
  padding: 0 0 44px;
  width: calc(100% - 48px);

  @media (--viewportLarge) {
    height: 100%;
    margin: 0;
    max-height: 312px;
    max-width: 324px;
    padding: 0 0 44px;
    width: 100%;
  }
}

.cardImage {
  width: calc(100vw - 48px);

  @media (--viewportLarge) {
    height: 100%;
    max-height: 170px;
    width: 100%;
  }
}

.cardTitle {
  text-align: center;

  @media (--viewportLarge) {
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    line-height: 1.1;
    margin: 0;
    padding: 24px 0 0;
  }
}
.cardBodyText {
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.cardLinkContainer {
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
}

.cardLink {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 1.25;
  margin-top: 12px;
  text-align: center;
}
