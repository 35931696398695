@import url('../../marketplace.css');

.root {
}

.introduction {
  margin: 0;
  padding: 0;
}

.blockHeading {
  color: var(--matterColorAnti);
  margin: 0 0 12px 0;
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 1.2;
  letter-spacing: -0.1px;
  padding-top: 32px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 50px 0 20px 0;

    padding-bottom: 4px;
  }

  @media (--viewportLarge) {
    padding-top: 4px;
  }
}

.blockContent {
  font-weight: var(--fontWeightRegular);
  margin: 12px 0 30px 0;

  @media (--viewportMedium) {
    margin: 20px 0 46px 0;
  }
}

.saveMessage {
  margin: 12px 0 24px;
}

.keywordsHeading,
.meetingTypeHeading,
.suggestedTimes {
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 56px;
  }
}

.meetingType {
  cursor: pointer;
  text-align: center;

  & span {
    display: block;
    font-size: 14px;
    font-weight: var(--fontWeightRegular);

    &:nth-of-type(2) {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.submit {
  color: var(--textDark);
  margin-top: 32px;
}

.timezone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  margin-top: 15px;
}

.timezoneLabel {
  margin-right: 5px;
}

.timezoneText {
  margin-left: 5px;
}
