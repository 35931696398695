@import url('../../marketplace.css');

.root {
  position: relative;
}

.menuContent {
  position: absolute;
  top: 25px;
}

.menu {
  margin: 0;
  min-width: 312px;
  padding: 15px 0;
}

.menuButton {
  padding: 0;
}

.button {
  background-color: unset;
  background-position: left center;
  background-repeat: no-repeat;
  color: var(--textDark);
  font-weight: var(--fontWeightRegular);
  line-height: 1.22;
  min-height: unset;
  min-width: unset;
  margin: 0 0 0 24px;
  padding: 10px 36px;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus {
    background-color: unset;
    box-shadow: unset;
    text-decoration: none;
  }
}
.apple {
  background-image: var(--iconApple);
}
.google {
  background-image: var(--iconGoogle);
}
.office365 {
  background-image: var(--iconOffice365);
}
.outlook {
  background-image: var(--iconOutlook);
}
.outlookLive {
  background-image: var(--iconOutlookLive);
}
.subscribe {
  background-image: var(--iconCalendar);
  background-position: left 10px;
}
.download,
.downloadAll {
  background: var(--iconDownload);
  background-position-y: 10px;
}

.modalHeader {
  color: var(--textDark);
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.33;
  letter-spacing: -0.5px;
}

.singleEventDownloads .subscribe {
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 18px;
}

.separatorLabel {
  color: var(--matterColorAnti);
  display: block;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.25;
  padding: 18px 24px;
}
