@import url('../../marketplace.css');

.root {
  display: inline-block;
}

.menuButton {
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: var(--textDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  height: 40px;
  line-height: 40px;
  min-height: unset;
  padding: 0 17px;
  width: unset;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--marketplaceColor);
    box-shadow: var(--boxShadowFilterButton);
  }
}

.menuContent {
  border-radius: 4px;
  margin-left: -14px;
  margin-top: 8px;
  z-index: calc(var(--zIndexTopbar) - 1);
}

.menuItems {
  margin-top: 0;
  min-width: 320px;
}

.featuredLocations {
  padding-left: 35px;
}

.input {
  background: var(--iconMarker);
  background-position-x: 26px;
  border: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  line-height: 64px;
  min-height: 64px;
  margin-bottom: 8px;
  padding: 0 0 0 57px;
  vertical-align: middle;
}

.icon {
  display: none;
}

.topLocations {
  color: var(--matterColorAnti);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 64px;
  min-height: 64px;
  padding: 0 0 0 35px;
  vertical-align: middle;
}

.predictions {
  margin: 0;
  padding: 15px 0 0 35px;
}

.prediction {
  list-style-type: none;
}

.searchLink {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
}

.searchLink strong {
  font-weight: var(--fontWeightMedium);
}

.place {
  color: var(--textDark);
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 2.22;
}

.region {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
}

.noResults {
  padding: 35px 35px 10px 35px;
}

.isLoading {
  padding: 35px;
}

.clearButton {
  margin: 12px 24px 0 0;
}
