@import url('../../marketplace.css');

.loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 48px 0;
}

.offerFooter {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #e6e6e6;

  & ul {
    margin: 0;
    display: flex;
    gap: 16px;
  }
}

.offerFooterInner {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-size: 14px;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportSmallMax) {
    font-size: 14px;
    margin-right: 18px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.legalLink {
  line-height: 1.71;
}

.logo {
  object-fit: contain;
  object-position: left center;
  height: 40px;

  @media (--viewportSmallMax) {
    height: 24px;
    text-align: center;
  }
}
