@import url('../../marketplace.css');

.root {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin: var(--LandingPage_sectionMarginTop) 0 51px 0;
  max-width: calc(100vw - 48px);
  width: 100%;

  @media (--viewportSmallMax) {
    padding: 0 24px;
    max-width: 100%;
  }

  @media (--viewportMediumMax) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 24px;
  }

  @media (--viewportLarge) {
    max-width: 1062px;
    margin: 0 auto;
  }
}

.sectionTitle {
  color: var(--textDark);
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -1px;
  line-height: 1.17;

  @media (--viewportMediumMax) {
    font-size: 32px;
    font-weight: var(--fontWeightMedium);
    line-height: 0.94;
    letter-spacing: -0.67px;
  }
}

.mobileText {
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}
.regularText {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}
