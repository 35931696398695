@import url('../../marketplace.css');

.root {
  overflow: auto;

  @media (--viewportMedium) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
    max-width: 1056px;
    width: 100%;
  }
}

.title {
  @media (--viewportLarge) {
    padding-bottom: 50px;
  }
}

.sectionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--LandingPage_sectionMarginTop) 0 51px 0;
  max-width: calc(100vw - 48px);
  width: 100%;

  @media (--viewportSmallMax) {
    flex-direction: column;
    padding: 0 24px;
    max-width: 100%;
  }

  @media (--viewportMediumMax) {
    margin-bottom: 12px;
    margin-top: 24px;
  }

  @media (--viewportLarge) {
    max-width: 1062px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 0 auto;
  }
}

.listingCard {
  &:hover {
    text-decoration: none;
  }

  @media (--viewportSmallMax) {
    margin-bottom: 24px;
  }

  @media (--viewportMedium) {
    max-width: 30%;
    width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 324px;
    width: 100%;
  }
}
