@import '../../marketplace.css';

:root {
  --GivslySearchInput_inputHeight: 66px;
  --GivslySearchInput_sidePadding: 24px;
  --GivslySearchInput_sidePaddingDesktop: 36px;
}

.root svg {
  left: 25px;
  position: absolute;
  top: 22px;
  z-index: 2;
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  background: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 18px;

  height: 66px;
  line-height: 66px;
  max-width: 600px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 62px;
  position: relative;
  width: 100%;
  z-index: 1;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;
  vertical-align: middle;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */

  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightRegular);
  }
}

.icon {
  position: absolute;
  top: 0;
  z-index: 2;
}

.searchResults {
  @apply --marketplaceBodyFontStyles;

  margin: 0;
  padding: 26px 34px;

  position: absolute;
  width: 100%;
  top: var(--GivslySearchInput_inputHeight);
  left: 0;
  background-color: var(--white);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexTopbar) - 1);
}

.searchResultHeading {
  @apply --marketplaceH4FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--textLight);
}

.searchResultList {
  margin-top: 10px;
  margin-bottom: 20px;
}

.searchResultList:last-child {
  margin-bottom: 0;
}

.searchResultItem {
  &:hover,
  &:focus {
    outline: none;
  }

  & a,
  & a:hover {
    text-decoration: none;
    color: var(--textDark);
  }
}

.searchResultCompany {
  & .searchResultItemTitle {
    line-height: 2.2;
  }
}

.searchResultVolunteer {
  margin: 10px 0;
}

.searchResultItemTitle {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
}

.searchResultItemSubTitle {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  color: var(--textLight);
  line-height: 1.71;
}

.error {
  color: var(--failColor);
  margin-top: 0;
}
