@import url('../../../../marketplace.css');

.button {
  width: auto;
  padding: 20px 40px;
  background-color: var(--eventPrimaryColor);
  color: var(--eventTextColor);

  &:hover,
  &:focus {
    background-color: var(--eventPrimaryColorDarker);
  }
}

.inlineTextButton {
  width: auto;
  padding: 20px 40px;
  color: var(--eventPrimaryColor);
}
