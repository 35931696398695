@import url('../../marketplace.css');

:root {
  --margin-bottom: 32px;
}

.container {
  padding: var(--modalPadding);
}

.formContainer {
  padding: 24px 24px 200px 24px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }

  @media (--viewportLarge) {
    padding: 35px 60px;
  }
}

.formField {
  margin-bottom: var(--margin-bottom);
}

.tabNavContainer {
  border-bottom: 1px solid var(--matterColorNegative);
  border-top: 1px solid var(--matterColorNegative);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;

  @media (--viewportLarge) {
    padding-top: 0;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.tabButton {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.tabButtonSelected {
  color: var(--marketplaceColor);
  border-bottom-color: var(--marketplaceColor);
}

.formText {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightRegular);
}

.formTextHeader {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  line-height: 1.11;
}
.modalButtonContainer {
  @media (--viewportSmallMax) {
    background: var(--white);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.primaryButton {
  color: var(--textDark);
}
.secondaryButton {
}
