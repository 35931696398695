.nonprofit-impact-card {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
}

.impact-card-content-col,
.impact-card-impact-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.impact-card-content-col {
  width: 100%;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.impact-card-impact-col {
  min-width: 200px;
}

.impact-card-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #25262b;
  margin: 0;
}

.impact-card-category {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #868e96;
  margin: 0;
}

.impact-card-mission {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  margin: 0;
}

.impact-picture-fallback-container {
  width: 240px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}

.impact-cost {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
}

.impact-verb {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #868e96;
}

.impact-unit-count {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
}

.impact-phrase {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #868e96;
}
