@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

.root {
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.sectionTitleSuffix {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 85px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.formField {
  margin-bottom: 32px;
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-bottom: 25px;

  @media (--viewportMedium) {
    margin-bottom: 35px;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatarContainer {
  position: relative;
}

.avatar {
  width: 100%;
  height: 100%;
  max-width: var(--ProfileSettingsForm_avatarSize);
  max-height: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    max-width: var(--ProfileSettingsForm_avatarSizeDesktop);
    max-height: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarLabel {
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightRegular);
  text-align: center;
  padding: 10px;
  min-width: 250px;
  background-color: var(--matterColorLight);
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;
  margin: 20px 0;

  @media (--viewportMedium) {
    margin: 15px 0;
    transition: var(--transitionStyleButton);
    padding: 7px 10px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
    cursor: pointer;
  }
}

.avatarLabelIconEdit {
  stroke: var(--textDark);
  margin-right: 15px;
}

.avatarLabelIconAdd {
  color: var(--textDark);
  margin-right: 15px;
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportSmallMax) {
    font-size: 16px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 18px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  margin-top: 12px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.firstName {
  @media (--viewportMedium) {
    width: calc(50% - 9px);
  }
}

.lastName {
  @media (--viewportMedium) {
    width: calc(50% - 9px);
  }
}

.validLocation {
  border-color: var(--successColor);
}
