@import '../../marketplace.css';

.root {
  min-height: 232px;
  padding-left: 0;
  width: 100%;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.thumbnail {
  display: inline-block;
  position: relative;
  width: 50%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.threeToTwoWrapper {
  position: relative;
}

.removeImage {
  background: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Ccircle%20cx%3D%2218%22%20cy%3D%2218%22%20r%3D%2218%22%20fill%3D%22%23FAC51D%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cg%20stroke%3D%22%231C1A34%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-linecap%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22M10.75%2013.75h14.5%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M15%2012.25c0-.828.806-1.5%201.8-1.5h2.4c.994%200%201.8.672%201.8%201.5%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M18%2016v7M20.5%2016l-.5%207M15.5%2016l.5%207%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M23.5%2013.25l-1.04%2010.203a2%202%200%200%201-1.989%201.797H15.53a2%202%200%200%201-1.99-1.797L12.5%2013.25%22%2F%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A')
    left top no-repeat;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  padding: 0;
  border: none;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}

.aspectWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.thumbnailLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
