@import url('../../marketplace.css');

.root {
}

.blockHeading {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.1px;
  line-height: 1.2;
  padding-top: 30px;
}

.text {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  line-height: 1.6;
}

.radioGroup {
  & ul {
    display: flex;
    flex-direction: row;
  }

  & ul li {
    padding: 0 24px 0 0;
  }

  & ul li label {
    padding: 0;
  }

  & ul li label span {
    margin-right: 10px;
  }
}

.followingPerson {
  align-items: flex-start;
  border-bottom: 1px solid var(--matterColorNegative);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 96px;
  padding: 23px 0 25px 0;

  @media (--viewportMedium) {
    align-items: center;
  }
}
.followingPersonInnerWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding-left: 37px;
  }
}

.personImage {
  background-color: transparent !important;
  display: inline-block;
}
.personDetail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.name {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  line-height: 1.11;
  padding-bottom: 5px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}
.jobTitle,
.companyName {
  color: var(--textLight);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
}

.fieldDescription {
  font-size: 16px;
  line-height: 1.5;
}

.unfollowButton {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  height: auto;
  line-height: 39px;
  margin-top: 12px;
  max-width: 80px;
  min-height: unset;
  padding: 0 14px;
  vertical-align: middle;
  width: auto;

  @media (--viewportMedium) {
    margin-top: unset;
    max-width: unset;
    padding: 0 21px;
  }

  &:hover {
    background: transparent;
  }
}
