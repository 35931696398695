@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.receipt {
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-size: 14px;
  flex-shrink: 0;
  margin: 0 24px;
  padding-bottom: 42px;
  padding-top: 24px;
  text-align: center;

  @media (--viewportLarge) {
    padding-top: 30px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.slotsListWrapper {
  padding: 0 24px;
  @media (--viewportMedium) {
    padding: 0 0 36px 0;
  }
}

.slotsExist {
  color: var(--marketplaceColorDark);
  cursor: pointer;
  font-weight: var(--fontWeightBold);
}

.fullyBookedTooltip {
  text-align: left;
  color: var(--textDark);
  font-weight: var(--fontWeightRegular);
  background-color: var(--white);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);

  & :global(.tippy-content) {
    padding: 20px;
  }

  & :global(.tippy-arrow) {
    border-top-color: var(--white) !important;
  }
}

.navButton {
  width: 20px;
  top: -5px;
  position: absolute;
  cursor: pointer;
  display: inline;
}

.navPrevButton {
  right: 20px;
}

.navNextButton {
  right: 0;
  text-align: right;
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.commissionFeeDivider {
  width: 100%;
  height: 1px;
  margin: 12px 0 10px 0;
  border: none;
  background-color: var(--matterColorNegative);
}

.modalHeader {
  font-weight: var(--fontWeightMedium);
  font-size: 30px;
  letter-spacing: -0.5px;
  margin-bottom: 32px;
}

.modalDescription {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  padding-bottom: 24px;
  white-space: pre-wrap;
}

.modalButton {
  background-color: var(--marketplaceColorLight) !important;
  border-radius: 4px !important;
  color: var(--textDark) !important;
  margin-bottom: 12px !important;
}

.modalCloseButton {
  background: transparent !important;
  border: 1px solid var(--matterColorNegative) !important;
  border-radius: 4px !important;
  color: var(--matterColorDark) !important;
  margin-bottom: 32px !important;
}

.selectedDay {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  line-height: 1.11;
  margin: 36px 0 20px 0;
  padding: 0;
}

.partOfEventText {
  display: block;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.25;
  margin-bottom: 20px;
  position: relative;
  margin-left: 20px;

  &::before {
    @apply --cssIconCircle;
    background-color: var(--marketplaceColorBlue);
    border: none;
    width: 12px;
    height: 12px;
    position: absolute;
    left: -20px;
    top: 3px;
  }
}
