@import url('../../marketplace.css');

.root {
}

.header {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  margin-top: 40px;
}

.copy {
  font-size: 16px;
  line-height: 1.5;
}

.btnCont {
  display: flex;
}

.btnCont button {
  margin-right: 20px;
}

.link .semiBold {
  font-size: 20px;
  margin-bottom: 0;
}

.link {
  margin-bottom: 20px;
}

.details {
  font-size: 16px;
  margin-top: 0;
  color: var(--textLight);
}

/* Offers table */
.offers {
  margin-bottom: 32px;
  width: 100%;
}

.offer {
  border: 1px solid var(--matterColorNegative);
  border-left: 0;
  border-right: 0;
  font-weight: var(--fontWeightRegular);
}

.offer td {
  font-size: 16px;
  padding: 12px 0;
}

.nameCol {
  width: 50%;
}

.donationCol {
  width: 15%;
}

.statusCol {
  width: 15%;
}

.viewsCol {
  width: 10%;
}

.controlsCol {
  width: 10%;
}

/* Offers table column labels */
.foot {
  color: var(--textLight);
  display: block;
  font-size: 12px;
}

.confirmed {
  color: var(--successColor);
}

/* Offer menu */
.menuContent {
  padding: 18px;
  width: 220px;
}

.menuLabel {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}

.menuItemCopy {
  background: var(--iconCopy) left center no-repeat;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 2;
  padding: 0 0 0 24px;
}

.menuItemRemove {
  background: var(--iconTrash) left center no-repeat;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 2;
  padding: 0 0 0 24px;
}

.capWarning {
  color: #ff0000;
  margin-top: 0;
  font-size: 16px;
  font-weight: 500;
}

.title {
  margin-bottom: 0;
}

.status {
  color: var(--textLight);
  font-size: 14px;
  line-height: 24px;
}

.statusActive {
  color: var(--successColor);
  font-weight: var(--fontWeightBold);
}

.statusInactive {
  color: var(--textLight);
  font-weight: var(--fontWeightBold);
}

.statusCapReached {
  color: var(--failColor);
  font-weight: var(--fontWeightBold);
}

.buttonBack {
  padding-left: 0;
  text-align: left;
}

.smallPrint {
  font-size: 15px;
}

.settings-table {
  & td {
    padding: 8px 0;

    &:not(:first-child) {
      padding-left: 16px;
    }
  }
}

.edit-container {
  position: relative;
  width: 100%;
}

.edit-text {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  padding-top: 4px;
  font-size: 12px;
  color: #b2b2b2;
}

@media (min-width: 1024px) {
  .edit-container {
    width: unset;
  }
}
