/* Fonts */
/* These are imported into marketplace.css */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightBold: 700;

  /* ================ Default font ================ */

  --marketplaceDefaultFontStyles: {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  --marketplaceSmallFontStyles: {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
  }

  /* ================ Body font ================ */

  --marketplaceBodyFontStyles: {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  /* ================ Hero title ================ */

  --marketplaceHeroTitleFontStyles: {
    font-weight: var(--fontWeightMedium);
    font-size: 64px;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: -2px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 25px;
    margin-bottom: 20px;

    @media (max-width: 400px) {
      font-size: 48px;
    }

    @media (--viewportMedium) {
      font-size: 64px;
      line-height: 64px;
      letter-spacing: -1.5px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 25px;
      margin-bottom: 31px;
    }
  }

  --marketplaceHeroSubTitleFontStyles: {
    font-weight: var(--fontWeightRegular);
    font-size: 24px;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
  }

  /* ================ Modal title ================ */

  --marketplaceModalTitleStyles {
    font-weight: var(--fontWeightMedium);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
  }

  /* ================ H1, H2, H3, H4, H5 & H6 ================ */

  --marketplaceH1FontStyles: {
    font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 18px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      font-size: 48px;
      font-weight: var(--fontWeightMedium);
      line-height: 56px;
      letter-spacing: -2px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  --marketplaceH2FontStyles: {
    font-weight: var(--fontWeightMedium);
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.3px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 21px;
    margin-bottom: 17px;

    @media (--viewportMedium) {
      line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 21px;
      margin-bottom: 19px;
    }
  }

  --marketplaceH3FontStyles: {
    font-weight: var(--fontWeightMedium);
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.2px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 16px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  --marketplaceH4FontStyles: {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }

  --marketplaceH5FontStyles: {
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 24px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 14px;
    }
  }

  --marketplaceH6FontStyles: {
    font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
  }

  /* ================ Other fonts ================ */

  --marketplaceTinyFontStyles: {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
  }

  --marketplaceMessageFontStyles {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
  }

  --marketplaceMessageDateFontStyles {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 13px;
      line-height: 24px;
    }
  }

  --marketplaceTxTransitionFontStyles {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  --marketplaceSearchFilterLabelFontStyles {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 16px;
      line-height: 20px;
    }
  }

  --marketplaceSearchFilterSublabelFontStyles {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 18px;
  }

  --marketplaceListingAttributeFontStyles {
    font-family: GTWalsheimPro, Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightRegular);
    font-size: 18px;
    line-height: 32px;
  }

  /* ================ Tabbed navigation font styles ================ */

  --marketplaceTabNavFontStyles {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;

    @media (--viewportLarge) {
      font-weight: var(--fontWeightMedium);
      font-size: 24px;
      line-height: 32px;
    }
  }

  --marketplaceTabNavHorizontalFontStyles {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  }
}
