@import url('../../../marketplace.css');

.section {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.column {
  flex: 1 1 auto;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: calc(100% / 3 - 20px);
    margin-right: 20px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
