@import url('../../marketplace.css');

.root {
}

.panelHeaderContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.panelHeaderButtons {
  display: none;
  flex: 1 0 auto;
  margin-top: 24px;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.viewProfileDesktop {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  height: 40px;
  min-height: 24px;
  max-width: 150px;
  padding: 0 18px;

  &:hover {
    background-color: transparent;
    border-color: var(--textDark);
  }

  /** Hide on mobile **/
  @media (--viewportSmallMax) {
    display: none;
  }
}

.viewProfileMobile {
  margin-top: 18px;
  background: var(--white);
  color: var(--textDark);
  border: 1px solid var(--matterColorNegative);
  /** Hide on larger than mobile */
  @media (--viewportMedium) {
    display: none;
  }
  &:hover {
    background-color: transparent;
    color: var(--textDark);
  }
}

.copyProfileUrl {
  margin-top: 10px;
  color: var(--textDark);

  & .copyReadyIcon {
    stroke: var(--white);
  }

  &.copied {
    background-color: var(--marketplaceColor);
    color: var(--marketplaceColor);

    & .copyReadyIcon {
      stroke: var(--textDark);
    }
  }

  &:disabled {
    &:hover {
      background-color: var(--matterColorNegative);
      border-color: var(--matterColorNegative);
      color: var(--white);
    }
  }
}

.hiddenInputContainer {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: relative;
}

.hiddenInput {
  position: absolute;
}
