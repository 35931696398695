@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 825px;
  --SectionHero_desktopSubTitleMaxWidth: 600px;
}

.root {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 760px;
  /*padding: 0 calc(2000px - 1440px / 2);*/
  position: relative;
  width: 100%;
  max-width: 1440px;

  /* Text positioning is done with paddings */
}

.heroContent {
  z-index: 1;
  margin: 0 24px 0 24px;
  max-width: calc(800px + 36px + 36px);

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroImage {
  margin: 0 24px;
  width: calc(100vw - 48px);

  @media (--viewportLarge) {
    display: block;
    width: 500px;
    position: absolute;
    right: 30px;
    top: 0;
    z-index: 0;
  }

  @media (min-width: 1300px) {
    display: block;
    width: 663px;
    right: 50px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorDark);

  @media (--viewportXSmallMax) {
    font-size: 32px;
    font-weight: var(--fontWeightMedium);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -1px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceHeroSubTitleFontStyles;
  color: var(--matterColorDark);
  margin-bottom: 58px;

  @media (--viewportXSmallMax) {
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
    line-height: 1.56;
    letter-spacing: -0.15px;
    margin-bottom: 24px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopSubTitleMaxWidth);
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.heroSearch {
  position: relative;
  display: flex;
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  max-width: 600px;
  height: 66px;
  max-height: 66px;

  & svg {
    margin-left: 24px;
  }
}

.signUp,
.login {
  font-size: 18px;
  margin-top: 31px;

  @media (--viewportXSmallMax) {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    letter-spacing: -0.12px;
    line-height: 2.29;
    margin-top: 15px;
  }
}

.signUpButton {
  border-radius: 4px;
  max-width: 320px;
  margin-top: 34px;
  text-transform: capitalize;
}

.signUpButtonLink {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.mobileSearch {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  color: var(--matterColorAnti);
  font-size: 18px;
  letter-spacing: -0.15px;
  line-height: 65px;
  padding-left: 21px;

  @media (--viewportMedium) {
    display: none;
  }
}
.mobileSearch svg {
  margin-right: 19px;
}
.desktopSearch {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
