@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 30px;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  font-size: 16px;
  margin: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemCustomerCommission {
  font-weight: var(--fontWeightRegular);
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 0;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  flex: 1;
}

.lineItemCustomerCommission .itemLabel span {
  margin-right: 5px;
}

.lineItemCustomerCommission .itemLabel svg {
  vertical-align: text-top;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  margin: 0 0 0 10px;
  text-align: right;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportSmallMax) {
    margin-bottom: 12px;

    &:first-child {
      display: none;
    }
  }

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.beforeBreakdownDivider {
  @media (--viewportMedium) {
    margin-top: 12px;
  }
}

.totalLabel {
  font-weight: var(--fontWeightBold);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0;
}

.lineItemTotal .totalLabel {
  font-size: 16px;
}

.lineItemTotal .totalPrice {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.givslyFeeTooltip {
  color: var(--textDark);
  background-color: var(--white);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);

  & :global(.tippy-content) {
    padding: 10px 24px 31px 24px;
  }

  & :global(.tippy-arrow) {
    border-top-color: var(--white) !important;
  }
}

.tooltipHeading {
  font-weight: var(--fontWeightBold);
}

.notice {
  color: var(--failColor);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
  margin-top: 24px;
}

.title {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 1.2;
  letter-spacing: -0.1px;
  margin-top: 3px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.detailItemLabel {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  line-height: 1.29;
  color: var(--matterColorAnti);
}

.detailItemValue {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
}

.proposedDetailItemValue {
  padding-bottom: 8px;
}

.providerFootnote,
.providerFootnoteRed,
.customerFootnoteRed {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
  white-space: pre-line;
}

.providerFootnoteRed,
.customerFootnoteRed {
  color: var(--failColor);
}

.customerFootnoteRed {
  padding-top: 24px;
}

.copyFieldContent {
  display: flex;
  flex-direction: row;
  max-height: 20px;
  overflow-y: hidden;
}

.copyField {
  border: 0;
  flex-grow: 1;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
  padding: 0;
}

.copyButton {
  background: transparent var(--iconCopy) center center no-repeat;
  min-height: unset;
  padding: 0;
  width: 20px;

  &.copyButtonIsReady {
    background-color: transparent;
    background-image: none;
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }

  & svg {
    zoom: 0.7;
  }
}

.commissionFeeDivider {
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.commissionFeeContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 8px;
  @media (--viewportMedium) {
    margin-top: 18px;
  }
}

.commissionFeeInput {
  cursor: pointer;
  display: flex;
  width: auto;
  margin-right: 12px;
  flex: none;
  margin-top: 6px;
}

.commissionFeeLabel {
  cursor: pointer;
  display: flex;
  flex: auto;
  font-size: 14px;
  color: #28283c;
  padding: 0;
}

.creditCardDisclaimer {
  color: #b2b2b2;
  font-size: 14px;
  line-height: 18px;
}
