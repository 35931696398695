@import url('../../marketplace.css');

.root {
}

.exposeEmail {
  border-top: 1px solid var(--matterColorNegative);
  display: flex;
  flex-direction: row;
  order: 4;
  padding: 24px 0;

  @media (--viewportLarge) {
    border: none;
    order: 5;
    padding: 40px 0;
  }
}
.exposeEmailCheckbox {
  flex-shrink: 1;
  width: auto;
}
.exposeEmailLabel {
  flex-grow: 1;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.22;
}
