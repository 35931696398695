@import url('../../marketplace.css');

.root {
}

.header {
  font-size: 48px;
}

.subHeader {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  margin-top: 32px;
}

.nonprofitSelector {
  padding-bottom: 18px;
}
.nonprofitSelector:last-child {
  padding-bottom: 0;
}

.select {
  border: 0;
}

.donationAmounts {
  display: flex;
  flex-direction: column;
}

.formControls {
  display: flex;
  flex-direction: row;
  padding: 24px 0;
}

.formControls > *:first-child {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
  color: var(--textDark);
  margin-right: 12px;
}
.formControls > *:last-child {
  border-radius: 4px;
  margin-left: 12px;
}

.tamplateDetails {
  margin: 12px 0 40px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: var(--textLight);
}

.imageSelectors {
  display: flex;
  justify-content: space-between;
}

.imageSelectors img {
  width: 120px;
  height: 120px;
  transition: 0.3s padding;
}

.uploader {
  width: 120px;
  height: 120px;
}

.imgAndInput {
  position: relative;
}

.uploader label {
  width: 120px;
  height: 120px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  background: #fcfcfc;
  border: 2px dashed #e6e6e6;
  border-radius: 2px;
  color: #1c1a34;
  cursor: pointer;
}

.uploader input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.replaceImg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  background-color: #fcfcfc;
  color: transparent;
  background-image: var(--iconPencil);
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  border-radius: 3px;
  position: absolute;
  right: 5px;
  width: 30px;
  height: 30px;
  bottom: 5px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 1px solid #e6e6e6;
}

.activeImage {
  border: 2px solid #fac51d;
  border-radius: 4px;
  padding: 7px;
}

.smallPrint {
  font-size: 16px;
}

.smallPrintGray {
  font-size: 16px;
  color: #b2b2b2;
  line-height: 1.2;
}

.ingress {
  min-height: 120px;
}

.uploadedImageContainer {
  position: relative;
  display: flex;
  width: 300px;
  border: 1px solid #ececec;
  border-radius: 3px;
}

.uploadedImageContainerLogo {
  position: relative;
  display: flex;
  width: 180px;
  height: 180px;
  border: 1px solid #ececec;
  border-radius: 3px;
}

.uploadedImage {
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
}

.bgImageSection {
  display: flex;
  margin-bottom: 60px;
}

.instruc {
  margin-left: 24px;
  font-size: 16px;
  color: #e5b700;
  margin-top: 90px;
  cursor: pointer;
}
.toggleParagraph {
  display: inline-flex;
  align-items: center;
}

input[name='donationAmount'],
input[name='donationCap'] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' viewBox='86 64 280 528'%3E%3Cpath d='M207 91v27h-2l-9 2c-22 4-47 16-63 32-31 29-42 69-30 114 7 31 27 56 55 71 11 6 15 7 53 19 47 16 60 22 72 34 10 10 14 20 14 37 0 27-14 45-39 52-7 2-11 2-25 2-19 0-27-1-39-8-11-5-22-17-28-29s-10-25-11-39v-10H86v11c4 66 34 109 88 127l20 6 11 2h2v51h49v-51l31-7c19-7 34-16 47-29 20-20 30-45 32-75 1-37-9-67-31-88-16-16-23-19-78-39-43-15-54-19-64-26a56 56 0 0 1-25-49c0-12 1-18 5-26 9-17 27-26 51-26 34 0 55 17 61 48l2 13c0 10-3 9 35 9h34v-8c0-27-12-58-30-80-14-17-40-32-64-37l-6-1V64h-49v27z' fill='%23b2b2b2'/%3E%3C/svg%3E");
  background-size: 12px 18px;
  background-position: left 8px;
  padding-left: 15px;
}

@media screen and (min-width: 768px) {
  input[name='donationAmount'],
  input[name='donationCap'] {
    background-position: left 6px;
  }
}

.spinner svg {
  width: 100%;
  height: 100px;
  margin: 50px 0;
}
