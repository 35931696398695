@import '../../marketplace.css';

.content {
  max-width: 1024px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;

  @media (--viewportLarge) {
    padding: 0;
    justify-content: center;
    margin: 50px auto;
    text-align: center;
  }
}

.contentBlock {
  margin-bottom: 30px;
}

.heading {
  font-weight: var(--fontWeightMedium);

  @media (--viewportLarge) {
    font-weight: inherit;
  }
}

.buttons {
  max-width: 600px;
  margin: 0 auto;
}

.volunteerButton {
  width: auto;
  padding: 20px 60px;
  font-weight: var(--fontWeightMedium);
}

.bookMeetingButton {
  max-width: 650px;
  color: var(--marketplaceColorDark);
}

.tinyText {
  @apply --marketplaceH5FontStyles;
  color: var(--textLight);
  margin-top: 0;
  max-width: 650px;
}
