@import url('../../marketplace.css');

.root {
}

.container {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.card,
.cardActive {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #fcfcfc;
  padding: 24px;
  width: 324px;
  margin: 0px 20px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  background-color: #fff;
}

.cardActive {
  border-color: #fac51d;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.selectWord {
  font-weight: 200;
  font-size: 18px;
}

.card .circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid #979797;
  border-radius: 9em;
  margin-right: 10px;
}

.cardActive .circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #000;
  border-radius: 9em;
  margin-right: 10px;
  border: 4px solid #fac51d;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #25262b;
  margin: 0;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #868e96;
  margin: 0;
}

.divider {
  width: 100%;
  margin: 16px 0;
  border-bottom: 1px solid #e9ecef;
}

.stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.radio {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;

  /* identical to box height, or 25px */
  display: flex;
  align-items: center;
  gap: 8px;
  & input {
    accent-color: #fac51d;
  }
}

.img {
  width: 120px;
  height: 120px;
  border-radius: 9em;
  margin-bottom: 25px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.gray {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  text-align: center;
  color: #b2b2b2;
  margin: 0;
  margin-bottom: 8px;
}

.black {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  text-align: center;
  color: #1c1a34;
  margin: 0;
  margin-bottom: 8px;
}

.blackThin {
  font-size: 14px;
  line-height: 1.29;
  text-align: left;
  color: #1c1a34;
  margin: 0;
}

.selectBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 21px;
  padding-top: 21px;
  border-top: 1px solid #e6e6e6;
}

@media screen and (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    margin: 21px;
  }

  .cardActive {
    margin: 21px;
  }
}

.npoLink {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
