@import url('../../marketplace.css');

.root {
}

.header {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 1.2;
  letter-spacing: -0.1px;
}

.cardsContainer {
  border-bottom: 1px solid var(--matterColorNegative);
  border-top: 1px solid var(--matterColorNegative);
  padding: 30px 0;
}

.cards {
  table-layout: fixed;
  width: 100%;
}

.columnIcon {
  padding-right: 15px;
  width: 36px;
}
.columnCardNumber {
  font-size: 18px;

  @media (--viewportLarge) {
    width: 180px;
  }
}
.columnExpiration {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  text-align: left;
}
.columnControls {
  text-align: right;
  position: relative;
  width: 24px;
}

.cardIcon {
  width: 21px;
}
.cardNumberDesktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
.cardNumberMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.cardButton,
.cardButtonDelete {
  background-image: var(--iconHorizontalEllipsis);
  background-position: right center;
  border: 0;
  cursor: pointer;
  height: 32px;
  width: 24px;

  &:focus {
    outline: none;
  }
}

.cardButtonDelete {
  background-image: var(--iconTrash);
}

.contextMenu {
  background-color: var(--white);
  box-shadow: var(--boxShadowPopup);
  display: none;
  margin: 0;
  position: absolute;
  width: 290px;
  padding: 15px 0;
  z-index: calc(var(--zIndexTopbar) - 1);

  & button {
    background-position: 24px center;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 1.22;
    padding: 9px 20px 9px 60px;
    text-align: left;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}
.contextMenuOpen {
  display: block;
  left: -264px;
  top: 32px;
}
.contextMenuItem {
  margin: 0;
  padding: 0;
}

.buttonDefault {
  background-image: var(--iconHeart);
}
.buttonEdit {
  background-image: var(--iconEdit);
}
.buttonDelete {
  background-image: var(--iconTrash);
}

.controls {
  padding-top: 32px;
}
.buttonAdd {
  color: var(--textDark);
}

.skeletonLabel {
  background-color: var(--backgroundColorAlt);
  display: block;
  height: 18px;
  width: 80%;
}

.modalControls {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & button:first-child {
    margin-bottom: 8px;
  }

  @media (--viewportMedium) {
    flex-direction: row;

    & button:first-child {
      margin: 0 4px 0 0;
    }

    & button:last-child {
      margin-left: 4px;
    }
  }
}

.placeholder {
  color: var(--textDark);
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  line-height: 1.6;
}

.modalHeader {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.5px;
  line-height: 1.33;
}
.modalBody {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
}

.mobileLabel {
  @media (--viewportLarge) {
    display: none;
  }
}
.desktopLabel {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}
