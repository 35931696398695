@import url('../../marketplace.css');

.root {
}

.submitButton {
  color: var(--textDark);
  display: inline-block;
  margin-top: 60px;
  padding: 0 32px;
  width: auto;
}

.sectionContainer {
  margin-bottom: 70px;
}

.nonprofitFilters {
  display: none;

  @media (--viewportMedium) {
    display: block;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.nonprofitFiltersMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.bio textarea {
  margin-top: 10px;
  height: 100px;

  @media (--viewportMedium) {
    height: auto;
  }
}
