@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--textDark);
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  margin: 0 0 48px 0;

  @media (--viewportSmallMax) {
    font-size: 32px;
    font-weight: var(--fontWeightMedium);
    line-height: 1.25;
    letter-spacing: -0.67px;
  }
}

.cards {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.card {
  font-weight: normal;
  color: var(--textDark);
  background-color: var(--white);
  position: relative;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.extraBottomPadding {
  padding-bottom: 30px;
}

.card:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.imageWrapper {
  max-height: 170px;
  overflow: hidden;
  position: relative;

  @media (--viewportSmallMax) {
    max-height: unset;
  }

  &:before {
    bottom: 0;
    content: '';
    background-image: url("data:image/svg+xml;utf8,<svg height='216' width='324' xmlns='http://www.w3.org/2000/svg'><polygon points='0,170 0,216 324,216 324,170 324,130' style='fill:white' /></svg>");
    height: 100%;
    position: absolute;
    width: 100%;

    @media (--viewportSmallMax) {
      background-size: cover;
    }
  }
}

.image {
  max-width: 100%;

  @media (--viewportSmallMax) {
    width: 100%;
  }
}

.cardTitle,
.cardSubTitle,
.text,
.footerText {
  padding-left: 24px;
  padding-right: 24px;
}

.cardTitle {
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  margin-top: 25px;

  @media (--viewportSmallMax) {
    position: relative;
    /* Negative top margin with dynamic margin. The viewport width is measured between 375px and
     * 767px. Where it starts off with -25px and scales up to -50px to accommodate the relative
     * spacing. 392 = 767 - 375
     */
    margin-top: calc((100vw - 375px) / 392 * -25 - 25px);
  }
}

.cardSubTitle {
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  color: var(--textLight);

  @media (--viewportSmallMax) {
    position: relative;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: 35px;
}

.footerText {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  position: absolute;
  bottom: 25px;
}

/* CTA style overrides */
.cta .cardTitle,
.cta .cardSubTitle,
.cta .text,
.cta .footerText {
  padding-left: 0;
  padding-right: 0;
}
.cta .cardTitle {
  font-size: 24px;
  line-height: 1.33;
  margin-top: 24px;
}
.cta .text,
.cta .footerText {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.5;
}
.cta .footerText {
  font-weight: var(--fontWeightMedium);
}
.cta .footerText a {
  color: var(--marketplaceColorDark);
}

.linked {
  cursor: pointer;

  &:hover {
    box-shadow: var(--boxShadowListingCard);
  }
}
