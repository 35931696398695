@import url('../../marketplace.css');

.root {
}

.headingContainer {
}

.heading {
  color: var(--textDark);
  font-weight: var(--fontWeightMedium);
}

.subHeading {
  font-weight: var(--fontWeightRegular);
  white-space: pre-line;
}
