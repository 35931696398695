@import url('../../marketplace.css');

.root {
}

.form {
}

.modal {
  @apply --marketplaceModalBaseStyles;
  height: 100%;
  min-height: 100vh;
  max-width: 100vw;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 540px;
    min-height: auto;
    height: auto;
  }

  @media (--viewportLarge) {
    flex-basis: 720px;
  }
}

.modalPadding {
  padding: var(--modalPadding);

  @media (--viewportLarge) {
    padding: var(--modalPaddingMedium);
  }
}

.donationFields {
  margin-bottom: 40px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 60px;
  }
}

.donationField {
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    margin-right: 25px;
    width: calc(100% / 3);

    &:last-child {
      margin-right: 0;
    }
  }
}

.donationValueFieldLabel {
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  margin-bottom: 15px;
}

.submitButton {
  border-radius: 2px;
  color: var(--textDark);
  padding: 0 32px;
  width: 100%;
  @media (--viewportMedium) {
    width: auto;
  }
}

/**
 * Date picker
 */
.dayPicker {
  /* Containers */
  & :global(.DayPicker),
  & :global(.DayPicker_focusRegion),
  & :global(.DayPicker) > div > div,
  & :global(.CalendarMonthGrid_month),
  & :global(.CalendarMonthGrid_month__horizontal) {
    width: 100% !important;

    @media (--viewportMedium) {
      width: 756px !important;
    }
  }

  & :global(.CalendarMonthGrid_month__horizontal) {
    padding-right: 10px;
  }
  & :global(.CalendarMonthGrid) {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
  }
  & :global(.DayPicker_transitionContainer__horizontal) {
    height: auto !important;
    transition: none !important;
    width: 100% !important;
  }
  & :global(.CalendarMonthGrid__horizontal) {
    position: relative;
  }
  & :global(.CalendarMonthGrid_month__hidden) {
    display: none !important;
  }
  & :global(.CalendarMonthGrid__animating > div) {
    visibility: hidden !important;
  }

  /* Navigation */
  & :global(.DayPickerNavigation) {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
  }
  & :global(.DayPickerNavigation_button) {
    //background-color: var(--matterColorNeutral);
    //border: 1px solid var(--matterColorNegative);
    //border-radius: 0;
    //height: 40px!important;
    //left: unset!important;
    //position: unset;
    //right: unset!important;
    //top: unset!important;
    //width: 40px!important;

    &:hover {
      border-color: var(--matterColorNegative);
    }
  }

  /* Header */
  & :global(.CalendarMonth_caption) {
    color: var(--textDark);
    font-size: 24px;
    font-weight: var(--fontWeightBold);
    padding-top: 12px;
    text-align: left;
  }

  /* Grid header */
  & :global(.DayPicker_weekHeaders) {
    margin-left: 0;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorAnti);
    padding: 0 !important;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader_li) {
    padding: 0;
    text-align: left;
    width: calc((100vw - 2 * 24px) / 7) !important;
    @media (--viewportMedium) {
      width: 108px !important;
    }
  }
  & :global(.DayPicker_weekHeader_li) small {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
  }

  /* Grid */
  & :global(.CalendarMonth_table) {
    margin-top: 24px;
  }
  & :global(.CalendarMonthGrid) {
    height: auto !important;
    min-height: auto !important;
    left: 0 !important;
    width: 100% !important;
  }
  & :global(.CalendarMonthGrid_month__horizontal) {
    min-height: auto;
  }
  & :global(.CalendarMonth) {
    padding: 0 !important;
  }
  & :global(.CalendarMonth_table) {
    width: 100%;
  }
  & :global(.CalendarDay) {
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-collapse: separate;
    color: var(--matterColorDark);
    cursor: pointer;

    padding: 0;
    height: calc((100vw - 2 * 24px) / 7) !important;
    width: calc((100vw - 2 * 24px) / 7) !important;
    @media (--viewportMedium) {
      height: 108px !important;
    }

    &:hover {
      background-color: var(--matterColorNeutral);
    }
  }
  & :global(.CalendarDay__outside),
  & :global(.CalendarDay__blocked_out_of_range) {
    color: var(--matterColorAnti);
    cursor: not-allowed;
    border: 1px solid var(--matterColorNegative);

    &:hover {
      background-color: transparent;
    }
  }
}

.calendarMonth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: calc(2 * 40px); /* 40px = width of next and prev month buttons */
  position: relative;
  top: -12px; /* 12px = Height of default padding of day picker caption */
}

.calendarMonthButton {
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  min-height: auto;
  padding: 8px;

  @media (--viewportMedium) {
    width: auto;
    padding: 8px 16px 8px 12px;
  }
}

.calendarMonthButtonIcon {
  position: relative;
  top: -1px;
  left: -1px;

  @media (--viewportMedium) {
    margin-right: 10px;
  }
}

.calendarMonthButtonText {
  display: none;

  @media (--viewportMedium) {
    display: initial;
  }
}

.calendarDay {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: column;
    width: auto;
    min-width: 103px;
  }
}

.calendarDayRegularSlots {
  background-color: var(--marketplaceColorOpacity15);
}

.calendarDayEventSlots {
  background-color: var(--marketplaceColorBlueOpacity10);
}

.daySlots {
  align-self: flex-start;
  flex-grow: 1;
  margin: 0;
  @media (--viewportMedium) {
    padding-top: 10px;
  }
}
.daySlot {
  display: flex;
  flex-grow: 2;
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 12px;
  padding: 3px 0 0 3px;
  text-align: left;

  @media (--viewportMedium) {
    padding: 0 0 4px 12px;
  }
}

.daySlotAvailable {
  &::before {
    @apply --cssIconCircle;
    background-color: var(--marketplaceColorFaded);
    border: 1px solid var(--marketplaceColor);
  }
}
.daySlotBooked {
  &::before {
    @apply --cssIconCircle;
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
  }
}
.daySlotEventAvailable {
  &::before {
    @apply --cssIconCircle;
    background-color: var(--marketplaceColorBlueLightest);
    border: 1px solid var(--marketplaceColorBlueLight);
  }
}
.daySlotEventBooked {
  &::before {
    @apply --cssIconCircle;
    background-color: var(--marketplaceColorBlue);
    border: 1px solid var(--marketplaceColorBlue);
  }
}

.daySlotTime {
  flex-grow: 2;
  @media (--viewportSmallMax) {
    display: none;
  }
}
.dayName {
  display: inline-block;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  padding: 0 8px 2px 0;
  text-align: right;
  align-self: flex-end;
  @media (--viewportMedium) {
    padding: 0 12px 8px 0;
  }
}
.dayNameToday span {
  border-bottom: 2px solid var(--textDark);
  padding-bottom: 2px;
}
.arrow {
  border: solid var(--matterColorDark);
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 10px;
  padding: 3px;
  width: 10px;
}
.arrowButton {
  background-color: var(--matterColorNeutral);
  border: 1px solid var(--matterColorNegative);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  width: 40px;

  &:hover {
    background-color: var(--matterColorNegative);
  }
}
.arrowButtonLeft {
  border-right: 0;
  padding: 6px 0 0 16px;

  & .arrow {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
.arrowButtonRight {
  padding: 6px 0 0 11px;

  & .arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
.controlDisabled {
  background-color: var(--matterColorLight);
  cursor: default;

  &:hover {
    background-color: var(--matterColorLight);
  }
  & .arrow {
    border-color: var(--matterColorNegative);
  }
}

/**
 * Date picker legend
 */
.dayPickerLegends {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  padding-top: 24px;

  @media (--viewportMedium) {
    margin-bottom: 60px;
    flex-direction: row;
  }
}
.dayPickerLegend {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-left: 32px;
    margin-top: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  & > span {
    display: inline-block;
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
  }

  &::before {
    background-color: var(--marketplaceColorFaded);
    border: 1px solid var(--marketplaceColor);
    border-radius: 10px;
    content: '';
    display: inline-block;
    height: 20px;
    margin-right: 12px;
    width: 20px;
  }
}

.dayPickerBooked {
  &::before {
    background-color: var(--marketplaceColor);
  }
}

.dayPickerEvent {
  &::before {
    background-color: var(--marketplaceColorBlueLightest);
    border-color: var(--marketplaceColorBlueLight);
  }
}

.dayPickerEventBooked {
  &::before {
    background-color: var(--marketplaceColorBlue);
    border-color: var(--marketplaceColorBlue);
  }
}

/**
 * Modal
 */
.modalHeader {
  color: var(--textDark);
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
}

.modalHeaderTimeslotForm {
  padding: 60px 24px 24px;

  @media (--viewportLarge) {
    padding: 60px 60px 24px 60px;
  }
}

.modalContent {
  color: var(--textDark);
  font-weight: var(--fontWeightRegular);
}

.modalButton {
  margin-top: 40px;
}
.buttonConfirm {
  border-radius: 4px;
  color: var(--textDark);
  margin-bottom: 10px;
  margin-top: 28px;
}
.buttonCancel {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
  color: var(--matterColorDark);

  &:hover {
    background-color: transparent;
  }
}

.buttonList {
  font-weight: var(--fontWeightMedium);
  margin-bottom: 10px;
}

.calendarDownloadOptions {
  top: 50px !important;
}
