@import url('../../marketplace.css');

.root {
}

.title {
  font-size: 32px;
  letter-spacing: -0.75px;
  line-height: 36px;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 48px;
    font-weight: var(--fontWeightMedium);
    letter-spacing: -1px;
    line-height: 1.17;
    margin: 0 0 24px;
  }
}

.subTitle {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  line-height: 32px;
  margin: 0;
  padding: 0 24px 32px;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 1.17;
    margin: 0 0 54px;
    padding: 0;
  }
}

.videoContainer {
  display: block;

  @media (--viewportMediumMax) {
    & iframe {
      height: calc(0.5625 * 100vw);
      width: 100vw;
    }
  }

  @media (--viewportLarge) {
    height: 100%;
    margin: 0 auto;
    max-height: 594px;
    max-width: 1056px;
    text-align: center;
    width: 100%;
  }
}
