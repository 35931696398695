@import url('../../../../marketplace.css');

.titleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.browseAllButtonDesktop {
  display: none;
  font-weight: var(--fontWeightMedium);
  color: var(--eventPrimaryColor);

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.arrow {
  position: relative;
  top: 2px;
}

.browseAllButtonMobile {
  background-color: var(--white);
  border: 1px solid var(--matterColorNegative);
  color: var(--textDark);
  display: inline;
  margin-top: 25px;

  @media (--viewportMedium) {
    display: none;
  }

  &:hover,
  &:active {
    background-color: var(--eventPrimaryColorDarker);
    border-color: var(--eventPrimaryColorDarker);
    color: var(--eventTextColor);
  }
}

.title {
  @apply --marketplaceH1FontStyles;
}

.card {
  height: 100%;
}

.sliderNextPrevButton {
  background-color: var(--eventPrimaryColor);
  color: var(--eventTextColor);

  &:hover,
  &:active {
    background-color: var(--eventPrimaryColorDarker);
  }
}

.sliderDot {
  background-color: #4a4a4a;
}

.sliderDotActive {
  background-color: var(--eventPrimaryColorDarker);
}
