@import url('../../marketplace.css');

.root {
}
.title {
  font-size: 48px;
  line-height: 56px;
  margin: 24px 0 12px;
  padding: 0;
}
.heading {
  color: var(--textLight);
  font-size: 20px;
  line-height: 24px;
  margin: 40px 0 0 0;
  padding: 0;
}
.userData {
  font-size: 20px;
  line-height: 32px;
}
.status {
  color: var(--textLight);
  font-size: 14px;
  line-height: 24px;
}
.statusConfirmed {
  color: var(--successColor);
  font-weight: var(--fontWeightBold);
}
.statusCapReached,
.statusDiscarded {
  color: var(--failColor);
  font-weight: var(--fontWeightBold);
}
.statusPending {
  color: var(--brandPrimaryColor);
  font-weight: var(--fontWeightBold);
}
.controls {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}
.buttonProcess,
.buttonDiscard {
  color: var(--textDark);
  padding: 0 32px;
  width: auto;
}
.buttonProcess {
  margin-right: 6px;
}
.buttonDiscard {
  border: 1px solid var(--matterColorNegative);
  background: var(--white);
  margin-left: 6px;

  &:hover {
    background: var(--white);
  }
}
.buttonBack {
  padding-left: 0;
  text-align: left;
}
