@import '../../../../marketplace.css';

.root {
  margin-top: 32px;
  max-width: calc(100vw - 48px);
  width: 100%;

  @media (--viewportMedium) {
    margin-top: -40px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--white);
  font-size: 32px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.13;
  margin-bottom: 23px;
  padding-top: 20px;

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 1.75;
    letter-spacing: -0.67px;
    margin: 0;
    padding-bottom: 40px;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  color: var(--white);

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  font-size: 32px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.5px;
  line-height: 1.13;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 48px;
    font-weight: var(--fontWeightMedium);
    line-height: 0.88;
    letter-spacing: -0.6px;
    padding: 0 0 12px 0;
  }
}

.stepSubTitle {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.3px;
  line-height: 1.75;
  margin-top: 0;

  @media (--viewportMedium) {
  }
}

.stepText {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 35px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.actions {
  padding-bottom: 60px;
  text-align: center;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.button {
  @apply --marketplaceButtonStyles;
  border-radius: 4px;
  color: var(--textDark);
  display: inline-block;
  line-height: 65px;
  padding: 0 80px;
  vertical-align: middle;
  width: auto;

  @media (--viewportMedium) {
    margin-top: 54px;
  }
}
