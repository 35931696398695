@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Avatar_size: 40px;
  --Avatar_sizeMedium: 60px;
  --Avatar_sizeLarge: 96px;
  --Avatar_sizeExtraLarge: 120px;
  --Avatar_sizeHuge: 180px;
  --Avatar_sizeMega: 240px;

  /* Base for all avatars */
  --Avatar_base: {
    font-family: 'GTWalsheimPro', Helvetica, Arial, sans-serif;
    border-radius: 50%;

    /* Position possible initials to the center of the component */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Colors */
    background-color: var(--brandPrimaryColor);
    color: var(--matterColorLight);

    &:hover {
      text-decoration: none;
    }
  }
}

/* Small Avatar */

.root,
.root svg {
  /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
  @apply --Avatar_base;
  display: inline-block;

  /* Layout */
  width: var(--Avatar_size);
  height: var(--Avatar_size);
}

.initials {
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 4px;
  text-transform: uppercase;
}

.avatarImage {
  border-radius: 50%;
}

/* Medium Avatar */

.mediumAvatar,
.mediumAvatar svg {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeMedium);
  height: var(--Avatar_sizeMedium);
}

.mediumAvatar .initials {
  font-size: 26px;
  font-weight: var(--fontWeightMedium);
  padding-bottom: 5px;
}

/* Large Avatar */

.largeAvatar,
.largeAvatar svg {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeLarge);
  height: var(--Avatar_sizeLarge);
}

.largeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  padding-bottom: 6px;
}

/* Extra Large Avatar */
.extraLargeAvatar,
.extraLargeAvatar svg {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeExtraLarge);
  height: var(--Avatar_sizeExtraLarge);
}

.extraLargeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  padding-bottom: 6px;
}

/* Huge Avatar */

.hugeAvatar,
.hugeAvatar svg {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeHuge);
  height: var(--Avatar_sizeHuge);
}

/* TODO */
.hugeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  padding-bottom: 6px;
}

/* Mega Avatar */

.megaAvatar,
.megaAvatar svg {
  @apply --Avatar_base;

  /* Fixed dimensions */
  width: var(--Avatar_sizeMega);
  height: var(--Avatar_sizeMega);
}

/* TODO */
.megaAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  padding-bottom: 6px;
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
