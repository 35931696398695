@import url('../../marketplace.css');

.root {
}

.formContainer {
  position: relative;
}

.loader {
  background-color: white;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.85;
  padding-top: 50%;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9;
}

.isLoading .loader {
  display: block;
}
