@import url('../../../../marketplace.css');

.cards {
  margin-top: 50px;
}

.card {
  & img {
    margin-bottom: 80px;

    @media (--viewportMedium) {
      margin-bottom: 0;
    }
  }
}
