@import url('../../../marketplace.css');

.section {
  position: relative;
}

.slidesContainer {
  position: relative;
}

.slide {
  position: absolute;
  left: -200%;
  opacity: 0;
  transition: opacity var(--transitionStyle);
  height: 100%;
}

.active {
  display: block;
  position: static;
  left: 0;
  opacity: 1;
}

.button {
  display: none;

  width: 80px;
  height: 80px;
  border: none;
  border-radius: 50%;
  background-color: var(--marketplaceColor);
  position: absolute;
  top: calc(50% - 40px);
  cursor: pointer;

  @media (--viewportMedium) {
    display: block;
  }
}

.buttonIcon {
  fill: var(--white);
  stroke: var(--white);
}

.prevButton {
  left: -120px;
}

.nextButton {
  right: -120px;
}

.hiddenButton {
  display: none;
}

.dots {
  display: none;
  width: 100%;
  justify-content: center;
  margin-top: 25px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  background-color: #655100;
  margin-right: 12px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.dotActive {
  background-color: var(--marketplaceColor);
}
