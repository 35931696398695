@import url('../../marketplace.css');

.root {
}

.header {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.1px;
  line-height: 1.2;
}

.paymentOptions {
  margin: 0 0 32px 0;
  padding: 0;
}

.paymentOption {
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0 0 8px 0;
  padding: 20px 24px;

  &:hover {
    border-color: var(--textDark);
  }

  &:focus,
  &.selectedPaymentOption {
    border-color: var(--marketplaceColor);
  }
}

.addCreditCardOption {
  display: block;
  padding: 0;
}

.cardNumber {
  font-weight: var(--fontWeightRegular);
  min-width: 190px;
}

.expiresOn {
  font-weight: var(--fontWeightRegular);
}

.iconCreditCard {
  background: var(--iconCreditCard) left center no-repeat;
  flex-shrink: 1;
  width: 21px;
  margin-right: 11px;
}

.iconCredit {
  background: var(--iconCreditSingle) left center no-repeat;
  flex-shrink: 1;
  width: 21px;
  margin-right: 11px;
}

.label {
  flex-grow: 1;
}

.controls {
  display: flex;
  flex-direction: column;

  & button:first-child {
    margin-bottom: 8px;
  }

  @media (--viewportLarge) {
    flex-direction: row;

    & button:first-child {
      flex-grow: 1;
      margin-bottom: 0;
      margin-right: 4px;
    }

    & button:last-child {
      flex-grow: 1;
      margin-left: 4px;
    }
  }
}

.footNote {
  color: var(--textLight);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
  margin-top: 30px;
}

.addCreditCardOptionLabel {
  display: flex;
  flex-direction: row;
  padding: 20px 24px;
}

.addCreditCardForm {
  display: none;
  margin-top: 0;
  padding: 24px 20px;
}

.showAddCreditCardDetailsForm {
  border-color: var(--marketplaceColor);
  display: flex;
  flex-direction: column;

  &:hover {
    border-color: var(--marketplaceColor);
  }

  & .addCreditCardOptionLabel {
    border-bottom: 1px solid var(--matterColorNegative);
  }

  & .addCreditCardForm {
    display: block;
  }
}

.creditDescription {
  display: flex;
  flex-direction: column;
  margin: 24px 0;

  & img {
    margin: 0 auto;
    max-width: 252px;

    @media (--viewportLarge) {
      max-width: 336px;
    }
  }

  & h4 {
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    line-height: 1.1;
    margin: 24px 0 14px 0;
  }

  & p {
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }
}
