@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --AutocompleteInput_inputHeight: 50px;
  --AutocompleteInput_sidePadding: 24px;
  --AutocompleteInput_sidePaddingDesktop: 36px;
}

.root {
  position: relative;
}

.input {
  border-bottom-color: var(--attentionColor);
}

/*
Suggestions container can be overriden with new container styles for
size and position, etc.
*/
.suggestionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--AutocompleteBottomPadding);
  top: 100%;
  left: 0;
  background-color: var(--white);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

/* List of suggestions, with a responsive padding size */
.suggestions {
  @apply --marketplaceBodyFontStyles;

  margin: 0;
  padding: 14px 0;

  & li {
    color: var(--textDark);
    transition: var(--transitionStyleButton);
    margin: 0; /* Remove the double white space */

    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 9px var(--AutocompleteInput_sidePadding);

    @media (--viewportMedium) {
      padding: 10px var(--AutocompleteInput_sidePaddingDesktop);
      margin: 0;
    }
  }
}

.suggestion {
  border-left: 6px solid transparent;
  display: flex;
  flex-direction: column;
  font-weight: var(--fontWeightRegular);

  &:hover,
  &.highlighted {
    border-color: var(--marketplaceColor);
  }
}
