@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

  @media (--viewportMedium) {
    display: none;
  }
}

.heroDetails {
  flex-grow: 2;
  flex-basis: 100%;
}

.avatarWrapper {
  padding: 24px;
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.nameText {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  font-weight: var(--fontWeightBold);
  margin-right: 4px;
  line-height: 20px;
}

.jobTitle,
.companyName {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  line-height: 1.29;
}
