@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  position: relative;
  width: 100%;
  margin: 0 auto;

  @media (--viewportSmallMax) {
    display: none;
  }
}

.filters {
  display: inline-block;
  width: auto;

  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.eventTitle {
  display: inline-block;
}

.eventTitleButton {
  color: var(--marketplaceColorDark);
  margin-right: 25px;
}

.results {
  display: inline-block;
  color: var(--matterColorAnti);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.25;
  text-align: right;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: 11px;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightRegular);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.clearAllFilters {
  font-size: 16px;
  color: var(--marketplaceColorDark);
  margin-left: 15px;
  font-weight: var(--fontWeightMedium);
}
