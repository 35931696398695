@import url('../../marketplace.css');

.root {
  color: var(--textDark);
  font-size: 14px;
  line-height: 1.29;
  min-height: unset;
  padding: 12px 16px 12px 16px;
  width: 100%;

  @media (--viewportLarge) {
    width: unset;
  }
}
.label {
  display: inline-block;
  padding-left: 24px;
}

.styleDark {
}

.styleLight {
  background-color: var(--white);
  border: 1px solid var(--matterColorNegative);

  &:disabled {
    color: var(--white);
  }

  &:hover {
    background-color: var(--white);
    border-color: var(--textDark);
  }

  &:focus {
    background-color: var(--white);
  }
}

.styleSuccess {
  background-color: var(--successColor);
  color: var(--white);

  &:hover {
    background-color: var(--successColor);
    color: var(--white);
  }
}

.styleLink {
  background-color: transparent;
  border: 0;
  color: var(--marketplaceColor);
  font-size: 16px;
  font-weight: var(--fontWeightBold);

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.iconCart {
  background-image: var(--iconCart);
}
.iconEnvelope {
  background-image: var(--iconEnvelope);
}
.iconPlus {
  background-image: var(--iconPlus);
}
.iconArrowLeft {
  background: var(--iconArrowLeft);
}
.iconCopy {
  background-image: var(--iconCopy);
}
.iconArrowDown {
  background: var(--iconArrowDown);
}
.iconView {
  background-image: var(--iconView);
}
.iconDownload {
  background-image: var(--iconDownload);
}
.iconDownArrow {
  background-image: var(--iconDownArrow);
}
.iconPencil {
  background-image: var(--iconPencil);
}
.noIcon {
  padding-left: 16px;
}
.withIcon {
  background-position: left center;
  background-repeat: no-repeat;
}

.root:disabled {
  background-color: #dadada;
  color: #333;
  opacity: 0.5;
  border: none;
}

root:disabled:hover {
  border: none;
  box-shadow: none;
}
