@import '../../marketplace.css';

.root {
}

.slots {
  margin-bottom: 32px;
}

.slot {
  display: block;
  font-size: 14px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 12px;
  text-align: left;
  @media (--viewportMedium) {
    display: inline-block;
    width: auto;
    margin-right: 8px;
    text-align: center;
  }

  &:last-child {
    margin-right: 0;
  }
}

.slotButton {
  background: var(--marketplaceColor)
    url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215%22%20height%3D%2215%22%20viewBox%3D%220%200%2015%2015%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7.5%200a7.5%207.5%200%201%201%200%2015%207.5%207.5%200%200%201%200-15zM5.613%204.21a1%201%200%200%200-1.32.083l-.083.094a1%201%200%200%200%20.083%201.32L6.06%207.474%204.293%209.243l-.083.094a1%201%200%200%200%20.083%201.32l.094.083a1%201%200%200%200%201.32-.083l1.767-1.768%201.769%201.768.094.083a1%201%200%200%200%201.32-.083l.083-.094a1%201%200%200%200-.083-1.32L8.889%207.474l1.768-1.767.083-.094a1%201%200%200%200-.083-1.32l-.094-.083a1%201%200%200%200-1.32.083L7.474%206.06%205.707%204.293z%22%2F%3E%0A%3C%2Fsvg%3E%0A')
    right 12px top 12px no-repeat;
  border: 1px solid var(--marketplaceColor);
  border-radius: 2px;
  color: var(--textDark);
  cursor: pointer;
  padding-right: 36px;
}

.slotButtonDisabled {
  cursor: none;
  opacity: 0.6;
}

.slotPlaceholder {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textLight);
  font-weight: var(--fontWeightRegular);
}
