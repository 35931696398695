@import '../../marketplace.css';

.root {
  display: none;
  margin: 60px 0 43px 0;
  color: var(--textDark);
  font-weight: var(--fontWeightRegular);

  & :global(.accordion__item) {
    border-top: 1px solid var(--matterColorNegative);
    margin: 18px 0;
  }

  & :global(.accordion__item):last-child {
    border-bottom: 1px solid var(--matterColorNegative);
  }

  & :global(.accordion__button) {
    cursor: pointer;
    margin: 18px 0 18px 0;
    outline: none;
    vertical-align: middle;
  }

  & :global(.accordion__button):before {
    float: right;
    display: inline-block;
    position: relative;
    vertical-align: inherit;
    top: 5px;
    right: 2px;
    content: '';
    height: 10px;
    width: 10px;
    margin-left: 18px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
  }

  & :global(.accordion__button)[aria-expanded='true']::before {
    transform: rotate(-135deg);
  }

  & :global(.accordion__button)[aria-selected='true']::before {
    transform: rotate(-135deg);
  }

  & :global(.accordion__panel) p {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    line-height: 1.29;
  }

  @media (--viewportMedium) {
    display: block;
  }
}

.heading {
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--textLight);
}

.footer {
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
}
