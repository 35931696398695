@import url('../../marketplace.css');

.submitButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--matterColorNegative);
  padding: 24px;
}

.submitButton {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  padding: 12px;
  min-height: auto;
  display: inline-block;
  width: auto;
}

.submitButtonPrimary {
  color: var(--textDark);
}

.submitButtonSecondary {
  color: var(--textDark);
  background-color: var(--white);
  border: 1px solid var(--matterColorNegative);
  margin-right: 8px;

  &:hover {
    background-color: var(--white);
  }
}
