@import url('../../marketplace.css');

.root {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1057px;
    padding-top: 120px;
  }
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    height: 100%;
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    font-size: 32px !important;
    margin-top: 0;
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.content {
  background: var(--backgroundColor);
  padding: 20px 24px;
  width: 100%;

  @media (--viewportMedium) {
    max-width: 648px;
    padding: 0 108px 0 0;
  }
}

.profile {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 408px;
  }
}

.profileLinkContainer {
  @media (--viewportMedium) {
    border-top: 1px solid var(--matterColorNegative);
    margin-top: 26px;
    padding-top: 26px;
  }
}

.profileLink {
  background: var(--iconExternalLink);
  background-position: left 2px;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 1.25;
  padding-right: 18px;
  vertical-align: center;

  @media (--viewportMedium) {
    background-position: right 2px;
    content: unset;
  }
}

.profileLinkLabel {
  display: none;

  @media (--viewportMedium) {
    display: unset;
  }
}

.card {
  border: 1px solid var(--matterColorNegative);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 24px;

  @media (--viewportMedium) {
    background: var(--white) var(--backgroundListingCard) center top no-repeat;
    background-size: contain;
    box-shadow: unset;
    flex-direction: column;
    padding: 44px 48px;
  }
}
.cardImage {
  align-self: flex-start;
  background-color: transparent;
  border-radius: 50%;
  height: 60px;
  margin-right: 20px;
  width: 60px;

  & img {
    height: 60px;
    width: 60px;
  }

  @media (--viewportMedium) {
    align-self: center;
    height: 180px;
    margin: 10px 0 25px 0;
    width: 180px;

    & img {
      height: 180px;
      width: 180px;
    }
  }
}
.cardInnerWrapper {
  display: flex;
  flex-direction: column;
}
.cardTitle {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 1.5;

  @media (--viewportMedium) {
    font-size: 24px;
    font-weight: var(--fontWeightBold);
    letter-spacing: -0.3px;
    line-height: 1.33;
  }
}
.cardSubTitle {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
}
.cardLocation {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
}

.sideBarHeader {
  color: var(--matterColorAnti);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.1px;
  line-height: 1.2;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 26px;
  padding: 27px 0 20px 0;

  @media (--viewportMedium) {
    border-top: 1px solid var(--matterColorNegative);
    margin-left: 0;
  }
}

.bookingForm {
}
