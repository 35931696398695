@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
  margin-top: 38px;
}

.section {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px 0;
}

.section + .section {
  border-top: 1px solid var(--matterColorNegative);
}

.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
}

.greetingSection {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 1px;
  max-width: 100%;
}

.greetingText {
  @apply --marketplaceH1FontStyles;
  margin: 0;
  font-weight: var(--fontWeightMedium);
  overflow-wrap: break-word;
}

.viewProfileLink {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  letter-spacing: normal;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.spacer {
  border-top: 1px solid var(--matterColorNegative);
  height: 1px;
  width: 100%;
  margin-top: 9px;
  margin-bottom: 18px;
}

.navigationLink {
  /* Font */
  @apply --marketplaceButtonFontStyles;
  color: var(--textDark);
  font-weight: var(--fontWeightRegular);
  line-height: 1.22;
}

.navigationLink + .navigationLink {
  margin-top: 18px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  margin-top: -2px;
  margin-left: 8px;
  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.logoutButton {
  @apply --marketplaceButtonStyles;
  padding: 0;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.miscLink {
  color: var(--textLight);
  margin-bottom: 18px;
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid var(--marketplaceColor);
  margin-left: -24px;
  padding-left: 19px;
}

.inlineSocialMediaList {
  display: flex;
  margin: 0;
  margin-bottom: 24px;
}
.inlineSocialMediaList li {
  margin-right: 48px;
}

.iconLink {
  /** Make the clickable area a bit bigger than only the icon */
  padding: 6px 6px 6px 0;
}

.login-link-small {
  color: #868e96;
  font-size: 12px;
  font-weight: 400;
  line-height: 18.6px;
  white-space: nowrap;
}

.login-link-stack {
  display: flex;
  flex-direction: column;
}
