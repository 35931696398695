@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
}

.dateSelector {
  padding-right: 6px;
  width: 50%;

  @media (--viewportLarge) {
    flex: 0 1 auto;
    margin-bottom: 0;
    margin-right: 24px;
    padding-right: 0;
    width: calc(100% / 2 - 24px);
  }
}

.timeSelector {
  padding-left: 6px;
  width: 50%;

  @media (--viewportLarge) {
    padding-left: 0;
    width: calc(100% / 2 - 24px);
  }
}

.calendarDay {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.daySelected {
  font-weight: var(--fontWeightBold);
  background-color: var(--marketplaceColor);
  border-radius: 50%;
}

.dayToday {
  & .dayName {
    border-bottom: 2px solid var(--textDark);
  }
}

.dayBlocked {
  background-color: var(--white);
  cursor: not-allowed;
  color: var(--matterColorNegative);

  &:hover {
    background-color: var(--white);
  }

  &.dayToday .dayName {
    border-bottom-color: var(--matterColorNegative);
  }
}

.dayName {
  line-height: 1.71;
}
