@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.title {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;

  @apply --marketplaceH1FontStyles;
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  color: var(--textDark);

  width: 324px;
  margin: 0 0 50px 0;

  @media (--viewportSmallMax) {
    font-size: 32px;
    line-height: 1.13;
    letter-spacing: -1px;
    width: auto;
  }

  @media (--viewportMedium) {
    margin: 0 40px 0 0;
    padding-right: 18px;
  }
}

.titleRow {
  @media (--viewportMedium) {
    display: block;
    white-space: nowrap;
  }
}

.quote {
  flex-basis: 0;
  flex-grow: 2;
  flex-shrink: 2;
  position: relative;

  background-color: var(--marketplaceColor);
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightRegular);
  color: var(--textDark);

  padding: 40px;

  @media (--viewportSmallMax) {
    padding: 24px;
  }

  & blockquote {
    margin: 10px 0 20px 0;
    padding: 0;
    line-height: 1.5;
  }
}

.quoteImage {
  position: absolute;
  top: -30px;
  left: 30px;
  width: 60px;
  border-radius: 50%;
}

.quoteName {
  font-weight: var(--fontWeightBold);
}

.quoteJobTitle {
  font-size: 14px;
}
