@import '../../marketplace.css';

:root {
  --GivslySearchInput_inputHeight: 73px;
  --GivslySearchInput_sidePadding: 24px;
  --GivslySearchInput_sidePaddingDesktop: 36px;
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 18px;

  height: 100%;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 18px;
  position: relative;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */

  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightRegular);
  }
}

.searchResults {
  @apply --marketplaceBodyFontStyles;

  margin: 0;
  padding: 26px 34px;

  position: absolute;
  width: 100%;
  top: var(--GivslySearchInput_inputHeight);
  left: 0;
  background-color: var(--white);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  max-width: 640px;
  z-index: calc(var(--zIndexTopbar) - 1);

  &:before {
    background-color: var(--white);
    content: '';
    display: block;
    height: 15px;
    position: absolute;
    left: 0;
    top: -10px;
    width: 100%;
    z-index: var(--zIndexTopbar);
  }

  @media (--viewportMediumMax) {
    height: 100vh;
    max-width: 768px;
    position: absolute;
    top: 90px;
  }
}

.searchResultHeading {
  @apply --marketplaceH4FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--textLight);
}

.searchResultList {
  margin-top: 10px;
  margin-bottom: 20px;
}

.searchResultList:last-child {
  margin: 0;
}

.searchResultItem {
  &:hover,
  &:focus {
    outline: none;
  }

  & a,
  & a:hover {
    text-decoration: none;
    color: var(--textDark);
  }
}

.searchResultCompany {
  & .searchResultItemTitle {
    line-height: 2.2;
  }
}

.searchResultVolunteer {
  margin: 10px 0;
}

.searchResultItemTitle {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
}

.searchResultItemSubTitle {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  color: var(--textLight);
  line-height: 1.71;
}

.error {
  color: var(--failColor);
  margin-top: 0;
}

/**
 * Default options, shown when no search query is entered but the search field has focus. Displays
 * the two option buttons to go directly to people search or nonprofit search. Also displays a list
 * of predefined/featured locations to perform a quick filtered search.
 */
.options {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.button {
  background-color: var(--white);
  background-position: 16px 10px;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  color: var(--textDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.25;
  min-height: unset;
  min-width: unset;
  padding: 8px 16px;
  width: auto;

  &:hover,
  &:active {
    background-color: var(--white);
  }
}
.buttonPeople {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2214%22%20viewBox%3D%220%200%2012%2014%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23FAC51D%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.923%208c3.266%200%205.923%202.692%205.923%206H9.523c0-2.01-1.615-3.647-3.6-3.647s-3.6%201.636-3.6%203.647H0c0-3.308%202.657-6%205.923-6zm0-8c1.924%200%203.484%201.58%203.484%203.53%200%201.949-1.56%203.529-3.484%203.529S2.44%205.479%202.44%203.529C2.439%201.58%203.999%200%205.923%200z%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  margin-right: 8px;
  padding-left: 38px;
}
.buttonNonprofits {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2218%22%20viewBox%3D%220%200%2020%2018%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23FAC51D%22%20fill-rule%3D%22evenodd%22%20d%3D%22M15.608%209.264l1.586%201.578L10%2018l-7.194-7.158%201.586-1.578L10%2014.844l5.608-5.58zm-7.91-3.958C9.014%204%2011.144%204%2012.459%205.306c1.313%201.308%201.313%203.427%200%204.735-1.315%201.307-3.445%201.307-4.76%200-1.313-1.308-1.313-3.427%200-4.735zm2.54-3.642c2.23-2.219%205.859-2.219%208.09%200%202.23%202.22%202.23%205.83%200%208.049L16.74%208.135c1.356-1.35%201.356-3.544%200-4.893-1.355-1.348-3.561-1.348-4.917%200zm-8.565%200c2.23-2.219%205.859-2.219%208.09%200L8.175%203.242c-1.356-1.348-3.562-1.348-4.917%200-1.356%201.35-1.356%203.544%200%204.893L1.673%209.713c-2.23-2.22-2.23-5.83%200-8.049z%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  padding-left: 46px;
}

.featuredLocationsLabel {
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightMedium);
  line-height: 1.5;
  font-size: 16px;
}

.searchBar {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (--viewportMediumMax) {
    left: 0;
    padding: 24px 0 20px 24px;
    position: absolute;
    top: 0;
    z-index: calc(var(--zIndexTopbar) + 1);
  }
}
