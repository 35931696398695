@import '../../marketplace.css';

.root {
  margin: 0;
}

.listItem {
  display: flex;
  flex-direction: row;
}
.itemLink {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
