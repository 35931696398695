@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 80px;
}

.root {
  background-color: var(--backgroundColor);
}

.layoutMainWrapper {
}

.sections {
  margin: 0;
}

.sectionVideo {
  @media (--viewportLarge) {
    padding-top: 80px;
  }
}

.embeddedVideo {
  @media (--viewportMedium) {
    height: calc(0.5625 * 100vw);
    width: 100vw;
  }

  @media (--viewportLarge) {
    height: 594px;
    max-width: 1056px;
  }
}

.sectionOtherImpacters {
  padding-top: 20px;

  @media (--viewportLarge) {
    padding: 80px 0 82px;
  }
}

.sectionImpactCards {
  margin-top: 40px;

  @media (--viewportLarge) {
    margin-top: 80px;
    max-width: 1056px;
  }
}

.sectionCallToActionFooter {
  background: url('../../assets/bg-pattern-bottom.svg') top center no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  height: 720px;
  justify-content: flex-end;
  margin: -250px auto 0 auto;
  padding-bottom: 100px;
  max-width: 2000px;
  width: 100%;

  @media (--viewportSmallMax) {
    height: auto;
    margin-top: -100px;
    padding-bottom: 12px;
    padding-top: 160px;
  }

  @media (--viewportMediumMax) {
    padding-bottom: 12px;
  }
}
