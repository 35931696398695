@import url('../../../../marketplace.css');

.contentContainer {
  width: 100%;

  @media (min-width: 1440px) {
    padding: 0 calc(((100vw - 1440px) / 2) + 24px);
  }
}

.section {
  @apply --eventLandingPagePaddings;
}

.logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  margin-top: -110px;
  padding-bottom: 50px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    margin-top: -50px;
    padding-bottom: 75px;
  }
}

.logoImage {
  max-height: 32px;
  max-width: 273px;
  margin-left: 12px;

  @media (--viewportMedium) {
    max-height: 57px;
    margin-left: 24px;
  }
}
