@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.tiles {
  order: 1;
}

.suggestion {
  text-align: center;
  font-size: 14px;
  line-height: 1.21;
  color: var(--textDark);

  & strong {
    display: block;
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    margin-bottom: 6px;
  }
}

.timezone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  order: 2;
  margin: 40px 0 16px;

  @media (--viewportLarge) {
    margin: 40px 0 0;
  }
}

.timezoneLabel {
  margin-right: 5px;
}

.timezoneText {
  margin-left: 5px;
}

.bookingDetails {
  order: 3;
}

.exposeEmail {
  border-top: 1px solid var(--matterColorNegative);
  display: flex;
  flex-direction: row;
  order: 4;
  padding: 24px 0;

  @media (--viewportLarge) {
    border: none;
    order: 6;
    padding: 40px 0;
  }
}
.exposeEmailCheckbox {
  flex-shrink: 1;
}
.exposeEmailLabel {
  flex-grow: 1;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.22;
}

.actionButtons {
  order: 6;

  @media (--viewportLarge) {
    order: 5;
  }
}

.errors {
  order: 5;
  margin: 0;
  padding: 0;

  @media (--viewportLarge) {
    order: 4;
  }
}
.actionError {
  color: var(--failColor);
  font-weight: var(--fontWeightMedium);
}
