@import url('../../marketplace.css');

.root {
}

.section {
  &:first-child {
    margin-top: 42px;
  }

  margin-top: 64px;
}

.sectionTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 135%;

  /* or 35px */
  letter-spacing: -0.02em;

  /* Dark/Dark 6 */
  color: #25262b;

  & .optional {
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    line-height: 1.25;
  }
}

.sectionDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* or 150% */
  font-feature-settings: 'liga' off;

  /* Brand / Navy */
  color: #1c1a34;
}

.field,
.staticField,
.impact-area-field {
  margin-top: 38px;
}

.staticField {
  display: flex;
  flex-direction: column;

  & .label {
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    line-height: 1.25;
  }

  & .value {
    font-size: 20px;
    font-weight: var(--fontWeightRegular);
    letter-spacing: -0.1px;
    line-height: 1.6;
  }
}

.picture {
  border-radius: 50%;
  height: 240px;
  width: 240px;
}

.pictureButton {
  cursor: pointer;
  margin-top: 16px;
}

.picturePlaceholder {
  background: var(--textDark);
  border-radius: 50%;
  height: 240px;
  width: 240px;
}

.pictureTip {
  color: var(--textLight);
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 1.33;
}

.pictureUploadInput {
  display: none;
}

.pictureUploadWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 44px;
    margin-bottom: 20px;
  }
}

.pictureAspectWrapper {
  height: 240px;
  width: 240px;
}

.submit {
  color: var(--textDark);
  padding: 0 64px;
  width: auto;
}

.impactAreaRow {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  gap: 24px;

  & > * {
    margin: 0;
    flex: 1 1 calc(50% - 24px);
  }
}

.input-row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.impact-input {
  padding: 8px 16px;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.impact-input-section {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.icon-input-wrapper {
  display: flex;
  align-items: baseline;
  position: relative;
  padding: 8px 8px 8px 16px;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.icon-input-wrapper:focus-within,
.icon-input-wrapper:hover {
  border-bottom-color: #1c1a34;
}

.icon-input-icon {
  margin-left: 4px;
  cursor: pointer;
  color: #adb5bd;
}

.icon-input-icon > span {
  font-size: 16px;
}

.icon-input-input {
  padding: 0;
  border: none;
  /* Gray/Gray 9 */
  color: #212529;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
}

.impact-area-field {
  & label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
  }

  & select {
    border: 1px solid #dadada;
    padding: 8px 16px;
    border-radius: 4px;
    background-size: 12px;
    background-position: calc(100% - 8px) center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 155%;
    color: #212529;
  }
}

.impact-area-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height, or 120% */
  letter-spacing: -0.1px;
  font-feature-settings: 'liga' off;

  color: #adb5bd;

  margin: 0;
}

.impact-area-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0;
  padding: 24px;
  gap: 24px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);
  border: solid 1px #e6e6e6;
  background-color: #fff;
}

.icon-input-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;

  position: absolute;
  width: 240px;
  min-height: 63px;

  /* Gray/Gray 9 */
  background: #212529;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height, or 121% */

  /* Gray/Gray 5 */
  color: #adb5bd;

  z-index: 1;
  top: -8px;
  transform: translate(50%, -50%) scale(0);
  opacity: 0;
  right: 0;
  transition: transform 200ms ease, opacity 200ms ease;
  transform-origin: center;
}

.icon-input-tooltip-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
  margin: 0;

  /* identical to box height, or 22px */
  display: flex;
  align-items: center;

  /* White */
  color: #ffffff;
}

.icon-input-icon:hover {
  color: #1c1a34;
}

.icon-input-group:first-child .icon-input-tooltip {
  transform: translate(0, -50%) scale(0);
  left: 0;
}

.icon-input-group:last-child .icon-input-tooltip {
  transform: translate(0, -50%) scale(0);
  left: unset;
  right: 0;
}

.icon-input-icon:hover + .icon-input-tooltip {
  transform: translate(50%, -100%) scale(1);
  opacity: 1;
}

.icon-input-group:first-child .icon-input-icon:hover + .icon-input-tooltip {
  transform: translate(0, -100%) scale(1);
  opacity: 1;
  left: 0;
}

.icon-input-group:last-child .icon-input-icon:hover + .icon-input-tooltip {
  transform: translate(0, -100%) scale(1);
  opacity: 1;
  left: unset;
  right: 0;
}

.caption {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height, or 129% */
  font-feature-settings: 'liga' off;

  /* Text/Grey 500 */
  color: #818181;
}

.data-list-container {
  border: 1px solid #ced4da;
  box-shadow: 0px 7px 7px -5px rgba(0, 0, 0, 0.04), 0px 10px 15px -5px rgba(0, 0, 0, 0.05),
    0px 1px 3px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 48px;
  background-color: #fff;
  padding: 8px 16px;
  left: -1px;
  z-index: 1;
  border-radius: 4px;
  display: none;
}

.icon-input-group:focus-within .data-list-container {
  display: block;
}

.data-list-container:empty {
  display: none !important;
}

.data-list-container div[role='option'] {
  color: #212529;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
}
