@import url('../../marketplace.css');

.listHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (--viewportMediumMax) {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
}

.listHeaderText {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 41px;
  margin: 0;
}
.listHeaderButton {
  align-self: flex-end;
  background: transparent
    url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%231C1A34%22%20fill-rule%3D%22evenodd%22%20d%3D%22M14%200a2%202%200%200%201%202%202v12a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V2a2%202%200%200%201%202-2h12zm-.333%203.5H2.333a.833.833%200%200%200-.827.729l-.006.104v9.334c0%20.425.318.775.729.827l.104.006h11.334a.833.833%200%200%200%20.827-.729l.006-.104V4.333a.833.833%200%200%200-.833-.833zM5%2011v1H4v-1h1zm2.333%200v1h-1v-1h1zm2.334%200v1h-1v-1h1zM5%208.5v1H4v-1h1zm2.333%200v1h-1v-1h1zm2.334%200v1h-1v-1h1zM12%208.5v1h-1v-1h1zM5%206v1H4V6h1zm2.333%200v1h-1V6h1zm2.334%200v1h-1V6h1zM12%206v1h-1V6h1z%22%2F%3E%0A%3C%2Fsvg%3E%0A')
    left 16px top 13px no-repeat;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 41px;
  min-height: 41px;
  padding: 0 16px 0 42px;
  vertical-align: middle;
  width: auto;

  &:hover {
    border-color: var(--textDark);
    text-decoration: none;
  }

  @media (--viewportMediumMax) {
    margin-bottom: 24px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.listItem {
  border-bottom: 1px solid var(--matterColorNegative);
  display: flex;
  flex-direction: column;
  padding: 24px 0 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.date {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  margin-bottom: 4px;
}
.time {
  font-weight: var(--fontWeightRegular);
}

.listItemTypeInfo {
  color: var(--matterColorAnti);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
  padding-left: 32px;
  @media (--viewportMedium) {
    flex-basis: 220px;
    flex-grow: 3;
    padding-left: 0;
  }
}

.eventName {
  text-wrap: avoid;
}

.what {
  text-wrap: avoid;
}

.status {
  margin-top: 5px;
}

.statusBooked {
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  &.event {
    color: var(--marketplaceColorBlue);
  }
}

.statusPending {
  font-weight: var(--fontWeightRegular);
  font-style: italic;

  &.event {
    color: var(--textLight);
  }
}

.dateTimeInfo {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  padding-left: 32px;
  margin-bottom: 8px;
  @media (--viewportMedium) {
    flex-basis: 250px;
    flex-grow: 2;
  }
}

.recurrenceRegular {
  background-position: left top 1px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23B2B2B2%22%20fill-rule%3D%22evenodd%22%20d%3D%22M14%200a2%202%200%200%201%202%202v12a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V2a2%202%200%200%201%202-2h12zm-.333%203.5H2.333a.833.833%200%200%200-.827.729l-.006.104v9.334c0%20.425.318.775.729.827l.104.006h11.334a.833.833%200%200%200%20.827-.729l.006-.104V4.333a.833.833%200%200%200-.833-.833zM5%2011v1H4v-1h1zm2.333%200v1h-1v-1h1zm2.334%200v1h-1v-1h1zM5%208.5v1H4v-1h1zm2.333%200v1h-1v-1h1zm2.334%200v1h-1v-1h1zM12%208.5v1h-1v-1h1zM5%206v1H4V6h1zm2.333%200v1h-1V6h1zm2.334%200v1h-1V6h1zM12%206v1h-1V6h1z%22%2F%3E%0A%3C%2Fsvg%3E%0A');

  &.recurring {
    background-image: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23B2B2B2%22%20fill-rule%3D%22evenodd%22%20d%3D%22M16%208a8%208%200%200%201-14.134%205.136L0%2015v-5h5l-2.069%202.07A6.5%206.5%200%200%200%2014.5%208H16zM8%200a7.987%207.987%200%200%201%206.136%202.866L16%201v5h-5l2.07-2.07A6.5%206.5%200%200%200%201.5%208H0a8%208%200%200%201%208-8z%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  }
}

.recurrenceEvent {
  &::before {
    @apply --cssIconCircle;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    background-color: var(--marketplaceColorBlueLightest);
    border-color: var(--marketplaceColorBlueLight);
  }

  &.booked {
    &::before {
      background-color: var(--marketplaceColorBlue);
      border-color: var(--marketplaceColorBlue);
    }
  }
}

.calendarDownloadOptions {
  top: 50px !important;
}

.listItemControls {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  position: relative;
  padding: 12px 32px;

  @media (--viewportMedium) {
    align-self: flex-end;
    padding: 0;
    min-width: 45px;
  }
}

.button.listItemControlButton {
  flex: 0 !important;
  margin-left: 12px !important;

  &:first-child {
    margin-left: 0 !important;
  }
}

.buttonDownload {
  background-image: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%231C1A34%22%20stroke-width%3D%221.5%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M15.25%2011v4.25H.75V11M8%200v10%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M11.571%207.5l-3.535%203.536L4.5%207.5%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
}

.buttonEdit {
  background-image: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%231C1A34%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M4.988%2013.987L.75%2015.25l1.264-4.239L12.276.75l2.974%202.974-9.295%209.297z%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M13%205.5L10.5%203M5.5%2013L3%2010.5%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
}

.buttonDelete {
  background-image: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%231C1A34%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20stroke-linecap%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22M.75%203.75h14.5%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M5%202.25c0-.828.806-1.5%201.8-1.5h2.4c.994%200%201.8.672%201.8%201.5%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M8%206v7M10.5%206l-.5%207M5.5%206l.5%207%22%2F%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M13.5%203.25l-1.04%2010.203a2%202%200%200%201-1.989%201.797H5.53a2%202%200%200%201-1.99-1.797L2.5%203.25%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
}

.noSlots {
  color: var(--textLight);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  text-align: center;
}

.button {
  background-color: transparent !important;
  background-position: left top !important;
  background-repeat: no-repeat !important;
  flex-grow: 1 !important;
  font-size: 2px !important;
  line-height: 16px !important;
  max-height: 16px !important;
  margin-left: 12px !important;
  min-width: 16px !important;
  height: 26px !important;

  @media (--viewportMedium) {
    height: auto;
  }
  /** There is a rule on .button in general for min height, avoid breaking the desktop by targetting only the small width screens */
  @media (--viewportSmallMax) {
    min-height: 26px;
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    box-shadow: none !important;
  }
  &:disabled,
  &[disabled] {
    background-color: transparent;
    cursor: default;
    visibility: hidden;
  }
}
