@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.noResults {
  color: var(--textLight);
  text-align: center;
}

.isLoading {
  text-align: center;
}
