@import '../../marketplace.css';

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
}

.dateSelector {
  width: 100%;
  flex: 1 0 auto;
  margin-bottom: 32px;

  @media (--viewportLarge) {
    margin-bottom: 0;
    margin-right: 12px;
    flex: 0 1 auto;
    width: calc(100% / 3 - 12px);
  }
}

.timeSelector {
  width: calc(100% / 2 - 6px);
  flex: 0 1 auto;
  margin-right: 6px;

  @media (--viewportLarge) {
    width: calc(100% / 3 - 12px);
    margin-right: 12px;
  }
}

.durationSelector {
  width: calc(100% / 2);
  flex: 0 1 auto;

  @media (--viewportLarge) {
    width: calc(100% / 3);
  }
}

.calendarDay {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.dayHasSlots {
  position: relative;
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    border: 1px solid var(--marketplaceColor);
    border-radius: 50%;
    background-color: var(--marketplaceColorLightest);
    position: absolute;
    bottom: 4px;
    left: calc(50% - 4px);
  }
}

.daySelected {
  font-weight: var(--fontWeightBold);
  background-color: var(--marketplaceColor);
  border-radius: 50%;
}

.dayToday {
  & .dayName {
    border-bottom: 2px solid var(--textDark);
  }
}

.dayBlocked {
  &:hover {
    background-color: initial;
  }
}

.dayName {
  line-height: 1.71;
}

.timezone {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  margin-top: 15px;
}

.timezoneText {
  margin-left: 5px;
}
