@import url('../../marketplace.css');

.content {
  max-width: 564px;
}
.header {
  color: var(--textDark);
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.17;
  letter-spacing: -1px;
}
.subHeader {
  color: var(--matterColorAnti);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  line-height: 1.2;
  letter-spacing: -0.1px;
}

.divider {
  border-bottom: 1px solid #e6e6e6;
}

.row {
  display: flex;
  justify-content: space-between;
}

.small {
  font-size: 16px;
  color: #979797;
}
