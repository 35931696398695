@import url('../../marketplace.css');

.title {
  @apply --marketplaceH1FontStyles
  display: inline-block;
}

.text {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  display: inline-block;
}

.errorContainer {
  min-height: 40px;
}

.error {
  font-size: 16px;
  color: var(--failColor);
  font-weight: var(--fontWeightRegular);

  & input {
    border-bottom-color: var(--failColor);
  }
}
