@import url('../../marketplace.css');

.root {
  display: inline-block;
  position: relative;
}

.popup {
  left: -14px;
  width: 900px;

  &.noOptions {
    width: 720px;
  }
}

.popupSize {
  min-height: 340px;
  padding: 0;
}

.filterContent {
  display: flex;
  flex-direction: row;
  position: relative;
}

.options {
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  width: 100vw;

  @media (--viewportMedium) {
    border-right: 1px solid var(--matterColorNegative);
    margin: 0;
    padding: 15px 0;
    width: 181px;
  }
}

.option {
  cursor: pointer;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.22;
  padding: 9px 0 9px 24px;

  @media (--viewportMedium) {
    padding-left: 32px;
  }
}

.selectedOption,
.option:hover {
  border-left: 4px solid var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  padding-left: 20px;

  @media (--viewportMedium) {
    padding-left: 28px;
  }
}

.option:hover {
  border-color: var(--textLight);
}

.selectedOption:hover {
  border-color: var(--marketplaceColor);
}

.buttonsWrapper {
  border-left: 1px solid var(--matterColorNegative);
  margin-left: 180px;

  &.noOptions {
    margin-left: 0;
  }
}

/**
 * Date range picker styling overrides
 */
.dayContents {
  border-radius: 18px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  vertical-align: middle;
  width: 36px;
}
.blocked {
  color: var(--textLight);
}

.calendar {
  @media (--viewportSmallMax) {
    background-color: var(--backgroundColor);
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;
    z-index: calc(var(--zIndexModal) + 1);

    &.calendarOpen {
      visibility: visible;
    }
  }

  & :global(.DayPicker) {
    border: none;
    box-shadow: none;

    @media (--viewportSmallMax) {
      height: 100vh;
      margin-top: 120px;
      overflow-y: scroll;
    }
  }

  & :global(.DayPicker) > div > div {
    width: 719px !important;

    @media (--viewportSmallMax) {
    }
  }

  & :global(.DayPicker) > div,
  & :global(.DayPicker) > div > div,
  & :global(.DayPicker_focusRegion) {
    @media (--viewportSmallMax) {
      height: 100vh;
    }
  }

  /**
   * Month grid (mobile)
   */
  & :global(.CalendarMonthGrid) {
    @media (--viewportSmallMax) {
      display: flex;
      flex-direction: column;
      width: 100vw !important;
    }
  }

  /**
   * Monthly level
   */
  & :global(.DayPicker_transitionContainer) {
    width: 100% !important;

    @media (--viewportSmallMax) {
      height: 1250px !important;
    }
  }
  & :global(.CalendarMonthGrid_month__horizontal) {
    padding: 0 25px 0 0;

    @media (--viewportSmallMax) {
      height: 350px;
    }
  }

  & :global(.CalendarMonth) {
    padding: 0 !important;
  }

  & :global(.CalendarMonth_table) tbody tr {
    border-bottom: 4px solid var(--white);
  }

  & :global(.CalendarMonth_table) {
    @media (--viewportSmallMax) {
      margin: 0 auto !important;
    }
  }

  /**
   * Headers
   */
  & :global(.DayPicker_weekHeaders) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 55px;

    @media (--viewportSmallMax) {
      flex-direction: column;
      width: 100vw;
    }
  }
  & :global(.DayPicker_weekHeader) {
    display: inline-block;
    left: unset !important;
    padding: 0 !important;
    position: unset;
    top: unset;
    width: 347px;

    @media (--viewportSmallMax) {
      margin: 0 auto 320px auto;
    }
  }

  & :global(.DayPicker_weekHeader_li) {
    width: 46px !important;
  }

  & :global(.DayPicker_weekHeader_li small) {
    color: var(--matterColorNegative);
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
  }

  /**
   * Day level
   */
  & :global(.CalendarDay) {
    border: none;
    box-shadow: none;
    color: var(--textDark);
    cursor: pointer;
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    padding: 0 !important;
    height: 36px !important;
    width: 46px !important;
  }

  & :global(.CalendarDay__default) {
    &:hover {
      background-color: unset;

      & span {
        background-color: var(--marketplaceColor);
      }
    }
  }

  & :global(.CalendarDay__selected_end),
  & :global(.CalendarDay__hovered_span:hover),
  & :global(.CalendarDay__selected_start) {
    background: linear-gradient(to left, var(--calendarSelection) 50%, var(--white) 50%);
    color: var(--textDark) !important;

    & span {
      background-color: var(--marketplaceColor);
      font-weight: var(--fontWeightBold);
    }
  }

  & :global(.CalendarDay__hovered_span:hover),
  & :global(.CalendarDay__selected_end) {
    background: linear-gradient(to left, var(--white) 50%, var(--calendarSelection) 50%);
  }

  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-color: var(--calendarSelection) !important;
    border-radius: 0;
  }

  & :global(.CalendarDay__blocked_calendar) {
    background-color: transparent;
    cursor: not-allowed;

    &:hover span {
      background-color: transparent;
    }
  }

  & :global(.CalendarDay__selected_start.CalendarDay__selected_end) {
    background: none !important;
  }

  /**
   * Misc
   */
  & :global(.DayPickerNavigation_leftButton__horizontalDefault) {
    background: var(--iconArrowLeft);
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    & svg {
      display: none;
    }
  }
  & :global(.DayPickerNavigation_rightButton__horizontalDefault) {
    background: var(--iconArrowRight);
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    & svg {
      display: none;
    }
  }
  & :global(.DayPickerNavigation_button__disabled) {
    visibility: hidden;
  }
  & :global(.DayPickerKeyboardShortcuts_buttonReset) {
    display: none;
  }
}

/**
 * Mobile
 */
.mobileFilter {
  padding-bottom: 0;
  padding-top: 0;
}
.mobileFilterLabel {
  position: absolute;
  right: 0;
  top: -58px;
}
.mobileTopControls {
  background-color: var(--white);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: calc(var(--zIndexModal) + 2);

  @media (--viewportMedium) {
    display: none;
  }
}
.buttonClose {
  background-color: transparent;
  color: var(--textDark);
  display: inline-block;
  font-size: 12px;
  font-weight: var(--fontWeightBold);
  letter-spacing: 2px;
  line-height: 1.17;
  padding: 0 24px;
  text-align: right;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    outline: 0;
  }
}
.mobileSelection {
  display: flex;
  justify-content: center;
}
.startDate {
}
.arrow {
  background: var(--iconArrowRightExtended);
  height: 20px;
  margin: 0 24px;
  width: 20px;
}
.endDate {
}
.mobileBottomControls {
  background-color: var(--white);
  bottom: 0;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  padding: 18px 24px;
  position: fixed;
  width: 100vw;
  z-index: calc(var(--zIndexModal) + 2);

  @media (--viewportMedium) {
    display: none;
  }
}
.buttonSetDates {
  color: var(--textDark);
  width: 100%;
}
