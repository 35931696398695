@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

/**
 * Result listing cards
 */
.listingCards {
  padding: 0 24px 24px 24px;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 904px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 24px 36px;
    max-width: 1440px;
    width: 100%;
  }

  @media (--viewportXLarge) {
    max-width: 1920px;
  }
}

.listingCard {
  flex-basis: 100%;
  margin: 0 auto 12px;
  max-width: 440px;

  @media (--viewportMedium) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
    margin: 0 24px 24px 0;
  }

  @media (--viewportLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    flex-basis: calc(25% - 24px);
    margin-right: 32px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media (--viewportMedium) and (--viewportMediumMax) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportLarge) and (--viewportLargeMax) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(4n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  margin-bottom: 24px;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

/**
 * No result content placeholder
 */
.noResults {
  padding: 0 24px;
  margin: 95px auto 0 auto;
  max-width: 624px;
  width: 100%;
  @media (--viewportMedium) {
    padding: 0;
  }
}
.noResultsImage {
  text-align: center;
}
.noResultsHeader {
  color: var(--textDark);
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  line-height: 1.11;
  text-align: center;
}
.noResultsDescription {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
  margin: 0;
  padding: 0;
  text-align: center;
}
.buttonClearAllFilters {
  background-color: transparent;
  box-shadow: none;
  color: var(--marketplaceColorDark);
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  text-align: center;

  &:hover,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }
}
