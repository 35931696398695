@import '../../marketplace.css';

.headingContainer {
  margin: 30px 0;
}

.heading {
  @apply --marketplaceH3FontStyles;
  color: var(--textLight);
  font-weight: var(--fontWeightBold);
}

.subHeading {
  @apply --marketplaceDefaultFontStyles;
  line-height: 1.6;
  font-weight: var(--fontWeightRegular);
}
