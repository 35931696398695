@import url('../../marketplace.css');

.root {
}

.isLoading {
  text-align: center;
}

.panel {
  max-width: 571px;
}

.introductionButton {
  color: var(--textDark);
  margin-top: 70px;
  padding: 0 50px;
  width: auto;
}
