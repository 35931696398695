@import '../../marketplace.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  align-items: stretch;
}

.twoColumns .item {
  flex-grow: 1;
}

.item {
  width: auto;
  user-select: none;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}

.input {
  display: none;
}

.label {
  background-color: var(--white);
  border-radius: 4px;
  border: solid 1px var(--matterColorNegative);
  cursor: pointer;
  height: 100%;
  padding: 30px 16px;

  @media (--viewportLarge) {
    padding: 30px 40px;
  }
}

.input:checked ~ .label {
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
}

.disabled {
  background-color: var(--matterColorNegative);
  cursor: not-allowed;

  & div {
    color: var(--white);
  }
}

.tooltip {
  margin-left: -75%;
}
.tooltip-hidden {
  display: none;
}
