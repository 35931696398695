@import url('../../marketplace.css');

.root {
}

.section {
  margin-top: 64px;
}

.section:first-child {
  margin-top: 42px;
}

.sectionTitle {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.1px;
  line-height: 1.2;
}

.sectionTitle .optional {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.25;
}

.optional {
  color: #b2b2b2;
}

.sectionDescription {
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.1px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.submit {
  color: var(--textDark);
  padding: 0 64px;
  width: auto;
}

.small {
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
}

.disclaimer {
  line-height: 24px;
  font-size: 16px;
  color: #b2b2b2;
}

.dateRow {
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
    max-width: 360px;
  }
}

.divider {
  border: none;
  border: 1px solid #e8e8e8;
  margin-top: 32px;
  margin-bottom: 32px;
}

.inputRow {
  position: relative;
}

.imageUploadLabel {
  margin-right: 32px;
  position: relative;
}

.imageUploadLabel button {
  background-color: rgba(255, 255, 255, 0.5);
  margin: 4px;
  border-radius: 50%;
}

.imageUpload {
  background-color: #fcfcfc;
  border: 2px dashed #e6e6e6;
  width: 180px;
  height: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  object-fit: cover;
  text-align: center;

  &:hover {
    border-color: #fac51d;
  }

  & + input[type='file'] {
    display: none;
  }
}

.uploadRow {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    margin-right: 32px;
    margin-bottom: 0;
  }

  & label {
    width: auto;
  }
}

.impactAreaRow {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 16px;

  & > * {
    flex: 1 1 calc(50% - 16px);
  }
}

.excludedAreasRow {
  position: relative;
  display: flex;
  gap: 16px;
  margin-top: 16px;

  & > * {
    flex: 1;
  }
}
