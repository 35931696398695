@import url('../../../../marketplace.css');

.heroContainer {
  @apply --eventLandingPagePaddings;
  color: var(--textDark);
  min-height: 350px;
  padding: 50px 24px;
  margin-top: 320px;
  white-space: pre-line;

  @media (--viewportLarge) {
    color: var(--white);
    margin-top: 0;
    min-height: 760px;
    padding: 50px 100px;
    white-space: unset;
  }

  @media (--viewportXLarge) {
    padding: 100px 200px;
  }
}

.textContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.textContainerMobile {
  padding-top: 75px !important;

  @media (--viewportLarge) {
    display: none;
  }
}

.logo {
  display: none;

  @media (--viewportLarge) {
    display: block;
    position: absolute;
    right: 80px;
    top: 50px;
  }

  @media (--viewportXLarge) {
    right: 150px;
    top: 80px;
  }
}

.textContainer {
  color: var(--textDark);
  padding: 24px;

  @media (--viewportLarge) {
    color: var(--white);
    padding: 0;
  }

  @media (--viewportLarge) {
    max-width: 70%;
  }
}

.eventName {
  display: block;

  @media (--viewportLarge) {
    display: inline;
  }
}

.eventNameSeparator {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.title {
  @apply --marketplaceHeroTitleFontStyles;
  font-size: 40px;

  @media (--viewportLarge) {
    @apply --marketplaceHeroTitleFontStyles;
  }
}

.text {
  font-size: 18px;
  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.button {
  margin-top: 25px;

  @media (--viewportLarge) {
    margin-top: 50px;
  }
}
