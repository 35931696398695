@import url('../../marketplace.css');

.root {
  background-color: var(--calendarSelection);
  border-radius: 8px;
  box-shadow: var(--boxShadow);
  color: var(--textDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  padding: 16px;
  position: relative;

  @media (--viewportMedium) {
    padding: 32px;
  }
}
.title {
  display: block;
  font-weight: var(--fontWeightBold);
  white-space: pre-line;
}
.text {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  white-space: pre-line;
}
.close {
  background: var(--iconX) center center no-repeat;
  background-size: cover;
  border: 0;
  cursor: pointer;
  height: 12px;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 12px;

  &:focus {
    outline: none;
  }
}
