@import url('../../marketplace.css');

.root {
}

.header {
  color: var(--textLight);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.1px;
  line-height: 1.2;
}
.description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    font-size: 20px;
    letter-spacing: -0.1px;
    line-height: 1.6;
    margin-bottom: 0;
  }
}
.controls {
  display: flex;
  flex-direction: column;

  & button {
    flex-grow: 1;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  @media (--viewportLarge) {
    flex-direction: row;

    & button {
      &:first-child {
        margin-bottom: 0;
        margin-right: 4px;
      }
      &:last-child {
        margin-left: 4px;
      }
    }
  }
}

.creditCodes {
  border-top: 1px solid var(--matterColorNegative);
  font-size: 18px;
  line-height: 1.11;
  margin-bottom: 32px;
  table-layout: fixed;
  width: 100%;
}
.creditCode {
  border-bottom: 1px solid var(--matterColorNegative);
}
.iconCreditSingle {
  background: var(--iconCreditSingle) left center no-repeat;
  width: 36px;
}
.iconCreditTotal {
  background: var(--iconCreditTotal) left center no-repeat;
  width: 36px;
}
.label {
  font-weight: var(--fontWeightBold);
  padding: 30px 0;
  min-width: 180px;
}
.expiration {
  font-weight: var(--fontWeightRegular);
  padding: 30px 0;
}
.balance {
  font-weight: var(--fontWeightRegular);
  padding: 30px 0;
  text-align: right;
}

.creditTotal {
  border-bottom: 1px solid var(--matterColorNegative);
  font-weight: var(--fontWeightBold);

  & .balance {
    font-weight: var(--fontWeightBold);
  }
}

.skeletonLabel {
  background-color: var(--backgroundColorAlt);
  display: block;
  height: 18px;
  width: 80%;
}

.mobileLabel {
  @media (--viewportLarge) {
    display: none;
  }
}
.desktopLabel {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}
