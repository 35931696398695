@import url('../../marketplace.css');

.image {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: auto;
  }
}

.container {
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
}
