@import '../../marketplace.css';

.root {
  color: var(--textDark);
  cursor: pointer;
  background-color: var(--marketplaceColor);
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  padding: 21px 24px;
  margin: 8px 0;

  &.eventSlot {
    background-color: var(--marketplaceColorBlue);
    color: var(--white);
  }
}

.iconLock {
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.tooltipText {
  font-size: 14px;
  line-height: inherit;
}

.selected {
  background-color: var(--white);
  border: solid 2px var(--brandPrimaryColor);
  cursor: default;

  &.eventSlot {
    background-color: var(--white);
    color: var(--textDark);
    border-color: var(--marketplaceColorBlue);
  }

  & .iconLock {
    fill: var(--marketplaceColorBlue);
  }
}

.date {
  display: block;
  font-size: 18px;
  font-weight: bold;
}

.time {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.11;
}

.description {
  font-size: 14px;
  color: var(--matterColorAnti);
}

.separator {
  background-color: var(--matterColorNegative);
  height: 1px;
  margin: 1em 0;
  border: 0;
}

.chooseMethod {
  color: var(--textLight);
  font-weight: bold;
  font-size: 14px;
  margin: 20px 0 10px 0;
}

.methodContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
}

.methodInput {
  cursor: pointer;
  display: flex;
  width: auto;
  margin-right: 10px;
  flex: none;
}

.methodLabel {
  cursor: pointer;
  display: flex;
  flex: auto;
  font-size: 14px;
}

.methodPrice {
  display: flex;
  width: 3em;
  flex: none;
}

.heading {
  display: flex;
  flex-direction: row;
}
.duration {
  flex-grow: 1;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.43;
  text-align: right;
}
