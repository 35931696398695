@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightMedium);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding: 0;
  border: none;
}

.keywordInput {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><g fill="none" fill-rule="evenodd" stroke="#FAC51D" transform="translate(1 1)"><circle cx="12.5" cy="7.5" r="7.5" stroke-width="1.875"/><path stroke-linecap="round" stroke-width="2" d="M6.95 13L.586 19.364"/></g></svg>');
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%23FAC51D%22%20transform%3D%22translate%281%201%29%22%3E%3Ccircle%20cx%3D%2212.5%22%20cy%3D%227.5%22%20r%3D%227.5%22%20stroke-width%3D%221.875%22%2F%3E%3Cpath%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20d%3D%22M6.95%2013L.586%2019.364%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-position-y: 4px;
  padding-left: 32px;
}
