.alertBanner {
  background-color: #fce283;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  padding: 0 16px;
}

@media (--viewportMedium) {
  .alertBanner {
    font-size: 16px;
    line-height: 24px;
  }
}

.bannerLink {
  color: #1c1a34;
  text-decoration: underline;
}

.bannerButton {
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
