@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 80px;
}

.root {
  background-color: var(--backgroundColor);
}

.layoutMainWrapper {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  @apply --backgroundImage;
  background-size: auto;
  background-position: center top;
  margin: 0 auto;
  width: 100%;

  @media (--viewportXSmallMax) {
    background-size: 250%;
  }

  @media (--viewportMedium) {
    max-height: none;
  }

  @media (--viewportLarge) {
    height: 760px;
    max-width: 2000px;
  }
}

.hero {
  flex-grow: 1;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;

  @media (--viewportMediumMax) {
    padding-top: 24px;
  }
}

.section {
  overflow: auto;

  @media (--viewportMedium) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.sectionContent,
.sectionHeading {
  margin: var(--LandingPage_sectionMarginTop) 0 51px 0;
  max-width: calc(100vw - 48px);
  width: 100%;

  @media (--viewportSmallMax) {
    padding: 0 24px;
    max-width: 100%;
  }

  @media (--viewportMediumMax) {
    margin-bottom: 12px;
    margin-top: 24px;
  }

  @media (--viewportLarge) {
    max-width: 1062px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 0 auto;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;

  @media (--viewportLarge) {
    margin-top: 3vh;
    padding-bottom: 50px;
  }
}

.sectionHeading {
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  @media (--viewportMediumMax) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.sectionTitle {
  color: var(--textDark);
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -1px;
  line-height: 1.17;

  @media (--viewportMediumMax) {
    font-size: 32px;
    font-weight: var(--fontWeightMedium);
    line-height: 0.94;
    letter-spacing: -0.67px;
  }
}

.sectionMoreLink {
  @media (--viewportMediumMax) {
    display: inline-block;
    font-size: 20px;
    padding-top: 10px;
  }
}

.featuredListings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (--viewportSmallMax) {
    flex-direction: column;
  }
}

.featureImage {
  height: 100%;
  width: 100%;
}

.listingCard {
  &:hover {
    text-decoration: none;
  }

  @media (--viewportSmallMax) {
    margin-bottom: 24px;
  }

  @media (--viewportMedium) {
    max-width: 30%;
    width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 324px;
    width: 100%;
  }
}

.lastSection {
  background: url('../../assets/bg-pattern-bottom.svg') top center no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: -300px auto 0 auto;
  padding-top: 300px;
  padding-bottom: 100px;
  max-width: 2000px;
  width: 100%;

  @media (--viewportSmallMax) {
    height: auto;
    margin-top: -100px;
    padding-bottom: 12px;
    padding-top: 160px;
  }

  @media (--viewportMediumMax) {
    padding-bottom: 12px;
  }
}

.mobileText {
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.regularText {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.browseAllMobile {
  background-color: var(--backgroundColor);
  border-radius: 4px;
  color: var(--textDark);
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 65px;
  position: relative;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    display: none;
  }
}

.featureBlockLogo1 {
  max-width: 100px;
  margin: 0 10px;
}

.featureBlockLogo2 {
  max-height: 80px;
  margin: 0 10px;
}

.featureBlockFooterText {
  line-height: 32px;
  height: 32px;
  vertical-align: middle;
}

.featureBlockButton {
  background-color: var(--white);
  color: var(--marketplaceColorDark);
  font-size: 18px;
  display: inline-block;
  padding: 21px 32px;
  border-radius: 4px;
  width: auto;

  &:hover {
    background-color: var(--white);
  }

  &:first-child {
    margin-right: 10px;
  }
}

.featureBlockButtonSecondary {
  background-color: transparent;
  color: var(--white);

  &:hover {
    background-color: transparent;
  }
}

.hero-2024 {
  position: relative;

  @media (--viewportSmallMax) {
    @apply --backgroundHeroMobile;
  }

  @media (--viewportMedium) {
    @apply --backgroundHeroDesktop;
    background-position-x: calc(100% + 200px);
  }

  @media (--viewportLarge) {
    @apply --backgroundHeroDesktop;
  }
}

.hero-2024-background {
  @apply --backgroundImageOutlineWhite;
}

.hero-2024-section {
  margin: 40px auto;

  @media (--viewportSmallMax) {
    margin-top: 0px;
    padding-top: 100%;
  }

  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
  }
}

.hero-2024-section-content-text {
  width: calc(50% - 32px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (--viewportSmallMax) {
    width: 100%;
  }
}

.hero-2024-section-content-text h1 {
  margin: 0 0 16px 0;
  font-weight: 500;
}

.hero-2024-section-content-text p {
  margin: 0 0 32px 0;
}

.hero-2024-link,
.section-2022-link {
  text-decoration: none !important;
}

.hero-2024-link,
.section-2022-link {
  @media (--viewportSmallMax) {
    width: 100%;
  }
}

.hero-2024-link-button,
.section-2022-link-button {
  padding: 16px 32px;
}

.section-companies p {
  text-align: center;
  margin: 0 0 50px 0;
}

.section-companies-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;

  @media (--viewportSmallMax) {
    flex-direction: column;
  }
}

.feature-block-2022 {
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media (--viewportSmallMax) {
    gap: 32px;
  }
}

.feature-block-2022-row {
  display: flex;
  gap: 64px;

  @media (--viewportSmallMax) {
    flex-direction: column;
    gap: 32px;
  }
}

.feature-block-2022-item {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @media (--viewportSmallMax) {
    gap: 16px;
  }
}

.feature-block-2022-item h2 {
  font-size: 72px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -3px;
  text-align: left;

  @media (--viewportSmallMax) {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.5px;
    text-align: left;
  }
}

.feature-block-2022-item h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.5px;
}

.feature-block-2022-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.feature-block-2022-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feature-block-2022-tab {
  &:first-child button {
    border-top: 1px solid #e6e6e6;
  }

  &:hover {
    border-right: 4px solid #e6e6e6;

    & button {
      color: #1c1a34;
    }
  }
}

.feature-block-2022-tab button {
  appearance: none;
  width: 100%;
  display: flex;
  gap: 8px;
  height: 80px;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  color: #b2b2b2;
  text-align: left;
}

.feature-block-2022-tab-active {
  border-right: 4px solid var(--marketplaceColor);
}

.feature-block-2022-tab-active button {
  color: #1c1a34;
}

.feature-block-2022-tab-content h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.3px;
}

.feature-block-2022-tab-content img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.feature-block-2022-tab-content a {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #1c1a34;
}

.feature-block-2022-tab-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.section-2022-get-started {
  padding: 64px;
  text-align: center;
  background-color: #fff;
  @media (--viewportSmallMax) {
    padding: 32px;
  }
}

.section-2022-get-started h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: -1.5px;
  text-align: center;
  margin: 0 0 16px 0;
}

.section-2022-get-started p {
  margin: 0 0 32px 0;
}

.section-2022-get-started-row {
  display: flex;
  gap: 16px;
  margin: 0 64px;

  @media (--viewportSmallMax) {
    flex-direction: column;
    margin: 0;
  }

  & a {
    flex: 1;
  }

  & button {
    padding: 16px 32px;
  }
}

.section-2022-get-started-outline {
  padding: 16px;
  @apply --backgroundImageOutlineWhite;
}

.section-2022-last {
  padding-bottom: 80px;
  border-bottom: 1px solid #e6e6e6;
}

.section-2022-npo {
  background-color: #faf4d5;
  margin: 80px 0 0;
  padding-bottom: 40px;
}
