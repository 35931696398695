@import url('../../../../marketplace.css');

.container {
  @apply --eventLandingPagePaddings;

  @media (--viewportLarge) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (--viewportDesktopFull) {
    padding-left: 150px;
    padding-right: 150px;
  }
}

.button {
  background-color: var(--white);
  color: var(--eventPrimaryColor);

  &:hover {
    background-color: var(--white);
  }
}

.buttonSecondary {
  background-color: transparent;
  color: var(--white);
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-top: 0;
  }

  &:hover {
    background-color: transparent;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
}

.smallTitle {
  color: var(--white);
  font-weight: var(--fontWeightBold);
  font-size: 20px;
}

.text {
  flex: 1 1 100%;
}

.featureContent {
  color: var(--eventTextColor);
}
