@import url('../../marketplace.css');

.root {
  padding: 0 24px;
  max-width: 1056px;
  margin: 0 auto;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0 36px;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerText {
  color: var(--textDark);
  font-size: 32px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.75px;
  line-height: 1.13;

  @media (--viewportMedium) {
    font-size: 48px;
    font-weight: var(--fontWeightMedium);
    line-height: 1.17;
    letter-spacing: -1px;
  }
}

.headerBrowse {
  background: transparent;
  color: var(--marketplaceColorDark);
  display: none;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  height: auto;
  min-height: unset;
  text-align: right;
  width: auto;

  &:hover {
    background: transparent;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    cursor: pointer;
    display: inline-block;
  }
}

.footerBrowse {
  background: var(--white);
  border-radius: 4px;
  border: solid 1px var(--matterColorNegative);
  color: var(--textDark);
  display: inline-block;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 64px;
  margin: 24px 0;
  vertical-align: middle;
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    display: none;
  }
}

.cards {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.card {
  margin-bottom: 40px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: var(--boxShadowListingCard);
  }

  @media (--viewportLarge) {
    max-width: 324px;
  }
}
