@import url('../../marketplace.css');

.root {
  padding-bottom: 120px;
}

.header {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 17px;

  @media (--viewportMedium) {
    font-size: 48px;
    letter-spacing: -1px;
    line-height: 1.17;
    padding-bottom: 27px;
  }
}

.introduction {
  border-bottom: 1px solid var(--matterColorNegative);
  margin-bottom: 40px;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 45px;
  }
}
.message {
  display: flex;
  flex-direction: column;
}

.messageFullWidth {
  flex-grow: 1;
}

.myMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.theirMessage {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.messages {
  display: flex;
  flex-direction: column;
}
.avatar {
  height: 40px;
  margin-right: 12px;
  width: 40px;
}
.text {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  padding: 12px 16px;
  max-width: 346px;
  width: 100%;
}
.keywords {
  padding-top: 12px;
}
.keywords .keyword {
  background-color: var(--marketplaceColorLightest);
  border-radius: 12px;
  color: var(--textOrange);
  display: inline-block;
  font-size: 13px;
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  margin-right: 4px;
  padding: 0 12px;
  vertical-align: middle;
}
.text .subHeader {
  color: var(--textLight);
  display: block;
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  line-height: 1.29;
  padding-top: 19px;
}
.timestamp {
  color: var(--textLight);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
  margin-top: 14px;
  max-width: 346px;
  width: 100%;
}

.myMessage .text {
  align-self: flex-end;
  background-color: var(--marketplaceColor);
}
.myMessage .subHeader {
  color: var(--textDark);
}
.myMessageTimestamp {
  align-self: flex-end;
  text-align: right;
}
.theirMessageTimestamp {
  text-align: left;
}

.buttonAddMeetingTimes {
  border-radius: 4px;
  color: var(--textDark);
  margin-top: 43px;
}

.options {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: -40px 0 0 0;
  padding: 0;
  width: 100%;
}

.option {
  align-items: center;
  border-bottom: 1px solid var(--matterColorNegative);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 11px 0 10px 0;
}

.optionLabel {
  flex-grow: 1;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.11;
}

.optionDate {
  display: block;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.optionSelectButton {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 39px;
  min-height: unset;
  max-width: 80px;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
    color: var(--textDark);
  }

  &:disabled {
    color: var(--white);
    cursor: not-allowed;

    &:hover {
      background-color: var(--matterColorNegative);
      color: var(--white);
    }
  }
}

.error,
.warning {
  color: var(--failColor);
  display: inline-block;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.25;
  max-width: 80px;
  text-align: center;
  width: 100%;
}

.warning {
  color: var(--marketplaceColorDark);
}
