@import url('../../marketplace.css');

.root {
  display: inline-block;
  width: auto;
}

.menuButton {
  color: var(--textDark);
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.1;
  height: unset;
  min-height: unset;
  padding-left: 26px;
  width: unset;
}
.menuButton .menuButtonContent {
  background: var(--iconArrowDown);
  background-position: right center;
  display: block;
  padding-right: 26px;
}
.menuButtonOpen .menuButtonContent {
  background: var(--iconArrowUp);
  background-position: right center;
}

.people {
  background: var(--iconPeople);
  background-position-y: 2px;
}
.nonprofits {
  background: var(--iconNonprofits);
  background-position-y: 2px;
}

.menuContent {
  margin-top: 14px;
  min-width: unset !important;
}

.menuItems {
  min-width: 150px;
}

.menuItem {
  color: var(--textDark);
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  height: 100%;
  line-height: 40px;
  max-height: 40px;
  padding-left: 32px;

  &:hover {
    border-left: 4px solid var(--marketplaceColor);
    padding-left: 28px;
    text-decoration: none;
  }
}
