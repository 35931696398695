@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container p {
  max-width: 840px;
  margin: 0 0 24px 0;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #1c1a34;
}

.ctaWrapper {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  align-items: center;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.ctaLink:hover {
  text-decoration: none;
}

.ctaLink:after {
  content: '';
  width: 28px;
  vertical-align: middle;
  height: 14px;
  display: inline-block;
  background: var(--iconArrowRight);
}

.ctaBtn {
  display: block;
  min-height: 65px;
  margin: 0;
  padding: 20px 30px;
  border: none;
  border-radius: 2px;
  background-color: #fac51d;
  color: #000;
  text-align: center;
  text-decoration: none !important;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-right: 24px;
    width: auto;
    margin-bottom: 0;
  }
}

.ctaBtn:hover,
.ctaBtn:focus,
.ctaBtn:active {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
}
