@import url('../../marketplace.css');

.card {
  background-color: var(--white);
  color: var(--textDark);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  position: relative;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.imageWrapper {
  max-height: 170px;
  overflow: hidden;
  position: relative;

  @media (--viewportSmallMax) {
    max-height: unset;
  }

  &:before {
    bottom: 0;
    content: '';
    background-image: url("data:image/svg+xml;utf8,<svg height='216' width='440' xmlns='http://www.w3.org/2000/svg'><polygon points='0,170 0,216 440,216 440,170 440,130' style='fill:white' /></svg>");
    height: 100%;
    position: absolute;
    width: 100%;

    @media (--viewportSmallMax) {
      background-size: cover;
    }
  }
}

.image {
  max-width: 100%;

  @media (--viewportSmallMax) {
    width: 100%;
  }
}

.cardTitle,
.cardSubTitle,
.text,
.footerText {
  padding-left: 24px;
  padding-right: 24px;
}

.cardTitle {
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  margin-top: 25px;

  @media (--viewportSmallMax) {
    position: relative;
    /* Negative top margin with dynamic margin. The viewport width is measured between 375px and
     * 767px. Where it starts off with -25px and scales up to -50px to accommodate the relative
     * spacing. 392 = 767 - 375
     */
    margin-top: calc((100vw - 375px) / 392 * -25 - 25px);
  }
}

.cardSubTitle {
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  color: var(--textLight);

  @media (--viewportSmallMax) {
    position: relative;
  }
}

.text {
  flex-grow: 1;
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 1.29;
}

.footerText {
  align-self: flex-start;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  padding-bottom: 16px;
}
