@import url('../../marketplace.css');

.root {
}

.fieldSelect {
  background: var(--iconCalendar) left top 40px no-repeat;
}
.fieldSelect select {
  padding-left: 32px;
}
