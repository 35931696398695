@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layoutWrapperMain {
  /* Use all available space parent element (aka root) can give and don't shrink content */
  flex-grow: 1;
  flex-shrink: 0;

  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;

  /* This was in the "full" landing page, but not on the light landing page
  @media (--viewportMedium) {
    margin: 0 30px;
  }
  */
}
