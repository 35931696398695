@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  @apply --marketplaceH4FontStyles;

  /* Avoid text going behind the submit button */
  padding: 22px 52px 25px 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  border-bottom: none;
  width: calc(100% - 52px);
  background-color: transparent;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.1px;

  @media (--viewportLarge) {
    border-bottom: 2px solid var(--attentionColor);
    font-size: 20px;
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 24px;
  top: -24px;

  @media (--viewportLarge) {
    position: unset;
    right: unset;
    top: unset;
  }
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: none;
  margin: 17px 0 0 0;
  width: auto;

  @media (--viewportLarge) {
    display: inline-block;
  }
}

.mobileSubmitButton {
  border: none;
  height: 48px;
  padding: 0;
  width: 48px;

  &:focus,
  &:hover,
  & {
    background-color: transparent;
    border: none;
    outline: none;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.sendIcon {
  margin: -3px 5px 0 0;
}
