@import url('../../marketplace.css');

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
  width: 700%;
}

.mainPanel {
  flex-grow: 1;
  max-width: calc(100% / 7);
}

.thirdPanel,
.sidePanel {
  flex-grow: 1;
  max-width: calc(100% / 7);
  overflow-y: visible;
}

.showMain {
  left: 0;
  animation: none;
}

.main_to_side {
  left: 0;
  animation: toSide 0.3s forwards;
}
.side_to_main {
  left: -200%;
  animation: toMain 0.3s forwards;
}
.side_to_third {
  left: -200%;
  animation: toThird 0.3s forwards;
}
.third_to_main {
  left: -400%;
  animation: toMain 0.3s forwards;
}
.third_to_fourth {
  left: -400%;
  animation: toFourth 0.3s forwards;
}
.fourth_to_third {
  left: -600%;
  animation: toThird 0.3s forwards;
}

@keyframes toMain {
  100% {
    left: 0;
  }
}
@keyframes toSide {
  100% {
    left: -200%;
  }
}
@keyframes toThird {
  100% {
    left: -400%;
  }
}
@keyframes toFourth {
  100% {
    left: -600%;
  }
}
