@import url('../../marketplace.css');

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
  width: 300%;
}

.mainPanel {
  flex-grow: 1;
  max-width: calc(100% / 3);
}
.sidePanel {
  flex-grow: 1;
  max-width: calc(100% / 3);
  overflow-y: visible;
}

.slideToSide {
  left: 0;
  animation: slideToSide 0.3s forwards;
}
.slideToMain {
  left: -200%;
  animation: slideToMain 0.3s forwards;
}
.showMain {
  left: 0;
  animation: none;
}

@keyframes slideToSide {
  100% {
    left: -200%;
  }
}
@keyframes slideToMain {
  100% {
    left: 0;
  }
}
