@import url('../../marketplace.css');

.modal {
  align-items: flex-end;
  flex-grow: 1;

  /* Modal_scrollLayer */

  & > div {
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(6px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: unset !important;
    align-items: flex-end;
    position: relative;

    @media (--viewportMedium) {
      background-color: unset;
      min-height: 100vh !important;
    }

    /* Modal_container */

    & > div {
      @media (--viewportSmallMax) {
        flex-direction: column;
        min-height: 100% !important;
        justify-content: flex-end;
      }

      background-color: transparent !important;
      display: flex;
      padding: 0 !important;

      @media (--viewportMedium) {
        justify-content: center;
        height: 100vh;
      }
    }

    /* Modal_content */

    & > div > div {
      background-color: var(--white) !important;
      display: flex;
      flex-direction: row;
      box-shadow: var(--boxShadowPopup);

      @media (--viewportMedium) {
        margin: 0 calc((100vw - 960px) / 2);
      }
    }

    /* Modal_close */
    & > div > button {
      @media (--viewportSmallMax) {
        position: relative;
        max-width: 95px;
        margin: 0 0 -60px calc(100vw - 95px);
      }

      @media (--viewportMedium) {
        margin-right: calc((100vw - 960px) / 2);
        top: 20px;
      }
    }
  }
}

.image {
  background-size: cover;
  display: none;
  min-width: 320px;
  max-width: 320px;
  width: 320px;

  @media (--viewportMedium) {
    background-image: url('./img-modal-signup@2x.jpg');
    display: block;
  }

  @media (--viewportLarge) {
    background-image: url('./img-modal-signup@3x.jpg');
  }
}

.content {
  padding: 24px;

  @media (--viewportMedium) {
    flex-grow: 1;
    padding: 60px 64px 52px 72px;
  }
}

.header {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.17;
  letter-spacing: -0.5px;
  padding-top: 24px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 1.33;
    letter-spacing: -0.5px;
    margin-top: 0;
    padding-top: 0;
    text-align: left;
  }
}

.body {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 0;
  text-align: center;
  white-space: pre-line;

  @media (--viewportMedium) {
    text-align: left;
    padding-bottom: 24px;
  }

  & p {
    font-size: 16px;
    line-height: 1.5;
    padding: 0;
    margin: 0;
  }
  & ul {
    margin: 34px 0 0 0;
    padding: 0;
  }
  & ul li {
    &::before {
      @media (--viewportMedium) {
        content: '•';
        display: block;
        height: 20px;
        min-width: 20px;
        width: 20px;
      }
    }

    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    line-height: 1.5;
    list-style: disc inside;

    @media (--viewportMedium) {
      display: flex;
      flex-direction: row;
      list-style-type: none;
    }
  }
}

.controls {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0;
  }
}

.primaryButton,
.secondaryButton {
  color: var(--textDark);
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  text-align: center;

  @media (--viewportMedium) {
    margin-right: 4px;
  }
}

.secondaryButton {
  background: var(--white);
  border: 1px solid var(--matterColorNegative);
  margin-top: 8px;

  &:hover {
    background-color: var(--white);
  }

  @media (--viewportMedium) {
    margin-left: 4px;
    margin-top: 0;
  }
}

.footer {
  color: var(--textLight);
  font-size: 14px;
  line-height: 1.71;
  margin-top: 24px;
  text-align: center;
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.footerLink {
  font-weight: var(--fontWeightBold);
}

/* Variant0 overrides */
.modal-variant0 {
  & .controls {
    @media (--viewportMedium) {
      flex-direction: column;
    }
  }

  & .primaryButton {
    margin-right: 0;
  }

  & .secondaryButton {
    margin-left: 0;
    margin-top: 8px;
  }
}
