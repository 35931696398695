@import url('../../marketplace.css');

.header {
  @apply --marketplaceModalTitleStyles;
}

.description {
  @apply --marketplaceModalParagraphStyles;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  margin-bottom: 24px;
}

.checkbox {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
}

.bottomWrapper {
  margin-top: 24px;
}
