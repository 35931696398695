@import '../../marketplace.css';

button.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  background: none;
  border: none;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  position: absolute;
  right: 0;
  top: 0;
  width: calc(50% - 6px);
  height: 100%;
  white-space: nowrap;

  @media (--viewportMedium) {
    margin: 0;
    width: calc(100% / 3 - 6px);
  }

  @media (--viewportLarge) {
    margin: 0;
    width: calc(100% / 3 - 24px);
  }
}

.viewPhotos span {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 12px;
  transition: background-color 300ms;
}

.viewPhotos:hover span {
  background: rgba(0, 0, 0, 0.4);
  transition: background-color 300ms;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  width: 100%;
  display: inline-block;
  position: relative; /* allow positioning own listing action bar */

  background: transparent;

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

img.nonprofitImage {
  margin-left: 12px;
  /** 50% and then minus the padding between */
  width: calc(50% - 6px);
  height: 100%;
  object-fit: cover;

  @media (--viewportMedium) {
    width: calc(100% / 3 - 6px);
  }

  @media (--viewportLarge) {
    height: auto;
    margin-left: 24px;
    width: calc(100% / 3 - 24px);
    max-height: 192px;
  }
}
/** trick to hide all but first two.. **/
.nonprofitImage + .nonprofitImage + .nonprofitImage {
  @media (--viewportSmallMax) {
    display: none;
  }
}

.aspectWrapper img:first-child {
  margin-left: 0;
}

.aspectWrapperSpaced .nonprofitImage:first-child {
  margin-left: 0;
}

.aspectWrapperJustified {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectionImages {
  @media (--viewportMedium) {
    padding-bottom: 32px;
    padding-top: 58px;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}
