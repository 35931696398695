@import '../../marketplace.css';

.submitButton {
  width: 100%;
  color: var(--textDark);

  @media (--viewportMedium) {
    width: auto;
    padding: 20px 80px;
  }
}
