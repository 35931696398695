@import url('../../marketplace.css');

.root {
}

.header {
  color: var(--textLight);
  font-size: 20px;
  line-height: 1.5;
  font-weight: var(--fontWeightBold);
}

/* Offers table */
.offers {
  margin-bottom: 32px;
  width: 100%;
}
.offer {
  border: 1px solid var(--matterColorNegative);
  border-left: 0;
  border-right: 0;
  font-weight: var(--fontWeightRegular);
}
.offer td {
  font-size: 16px;
  padding: 12px 0;
}
.nameCol {
  width: 50%;
}
.donationCol {
  width: 15%;
}
.statusCol {
  width: 15%;
}
.viewsCol {
  width: 10%;
}
.controlsCol {
  width: 10%;
}

/* Offers table column labels */
.foot {
  color: var(--textLight);
  display: block;
  font-size: 12px;
  line-height: 15px;
  padding-top: 5px;
}
.confirmed,
.statusActive {
  color: var(--successColor);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;
}
.statusCapReached,
.statusExpired {
  color: var(--failColor);
  font-weight: var(--fontWeightMedium);
}
.statusArchived,
.statusInactive {
  color: var(--textLight);
  font-weight: var(--fontWeightMedium);
}

/* Offer menu */
.menuContent {
  padding: 18px 0;
  width: 200px;
}
.menuLabel {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}
.menuItem {
  background-position: 18px center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1;
  padding: 8px 16px 8px 42px;
}

.menuItem:hover {
  background-color: #fac51d;
}

.menuItemCopy {
  background-image: var(--iconCopy);
}
.menuItemDetails {
  background-image: var(--iconInfo);
}
.menuItemRemove {
  background-image: var(--iconTrash);
}
.menuItemDeactivate {
  background-image: var(--iconTurnOnOff);
}
.menuItemEdit {
  background-image: var(--iconPencil);
}
.menuItemArchive {
  background-image: var(--iconArchive);
}
.empty {
  display: none;
}
.indicator {
  display: inline-flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  margin-right: 6px;
  font-weight: 600;
  background-color: red;
  border-radius: 9em;
  color: #fff;
}
