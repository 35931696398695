@import '../../marketplace.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  &:checked + label .checkmark {
    fill: var(--textDark);
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }

  &:focus + label .switchContainer,
  &:focus:checked + label .switchContainer {
    outline: 2px solid var(--marketplaceColor);
    outline-offset: 1px;
  }

  &:checked + label .switchContainer {
    background-color: var(--marketplaceColor);
  }
  &:checked + label .switchIndicator {
    transform: translateX(100%);
  }
}

.label {
  align-items: center;
  display: flex;
  padding: 0 0 6px 0;
  user-select: none;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}

.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}

.text {
  align-self: baseline;
}

.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
  line-height: 22px;
}

.switchContainer {
  height: 16px;
  width: 28px;
  display: inline-flex;
  background-color: #e6e6e6;
  border-radius: 16px;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  margin-right: 8px;
}

.switchIndicator {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 100ms ease-in;
}
