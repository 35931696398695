@import '../../marketplace.css';

div.tooltip {
  text-align: left;
  color: var(--textDark);
  font-weight: var(--fontWeightRegular);
  background-color: var(--white);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);

  & > :global(.tippy-content) {
    padding: 20px;
  }

  &[data-placement^='bottom'] > :global(.tippy-arrow) {
    border-bottom-color: var(--white);
  }

  &[data-placement^='left'] > :global(.tippy-arrow) {
    border-left-color: var(--white);
  }

  &[data-placement^='right'] > :global(.tippy-arrow) {
    border-right-color: var(--white);
  }

  &[data-placement^='top'] > :global(.tippy-arrow) {
    border-top-color: var(--white);
  }
}
