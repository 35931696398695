@import '../../marketplace.css';

.root {
  font-size: 16px;

  & p {
    @apply --marketplaceH4FontStyles;
    text-align: left;
    font-weight: var(--fontWeightRegular);
  }

  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }

  & li {
    font-size: inherit;
    font-weight: var(--fontWeightRegular);
  }

  & ul li {
    list-style: disc outside;
    margin-left: 2em;
    padding-left: 0.5em;
  }

  & table {
    border: 1px solid var(--textDark);
  }

  & table th,
  & table td {
    text-align: left;
    vertical-align: top;
    border: 1px solid var(--textDark);
    padding: 5px 10px;
  }
}

.emphasized {
  font-weight: var(--fontWeightRegular);
  text-decoration: underline;
}

.alphabetic li {
  list-style-type: upper-alpha;
}

li[data-marker]::marker {
  content: attr(data-marker);
}


.notification {
  background-color: #fff6a8;
  color: #000;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  text-align: center;
}

.notification p {
  margin: 0;
}

.notification a {
  color: #d32f2f;
  font-weight: bold;
  text-decoration: underline;
}