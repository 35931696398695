@import url('../../marketplace.css');

.root {
  display: flex;
  flex-direction: row;
}

.options {
  display: flex;
  flex-direction: row;
}

/***************************************************************************************************
 * Generic controls
 **************************************************************************************************/
.buttonCancel {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  color: var(--textDark);
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
  min-height: unset;
  padding: 0 15px;

  &:hover,
  &:focus {
    background: transparent;
    box-shadow: var(--boxShadowButton);
    color: var(--textDark);
  }
}

/***************************************************************************************************
 * Delete control
 **************************************************************************************************/
.toggleDelete {
  background: transparent var(--iconTrash) center center no-repeat;
  cursor: pointer;
  height: 100%;
  min-height: unset;
  width: 24px;

  &:hover,
  &:focus {
    background: transparent var(--iconTrash) center center no-repeat;
    box-shadow: unset;
  }
}

.buttonDelete {
  background: var(--failColor);
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.29;
  margin-right: 8px;
  min-height: unset;
  padding: 0 15px;

  &:hover,
  &:active {
    background: var(--failColor);
    box-shadow: var(--boxShadowButton);
    color: var(--white);
  }
}
