@import url('../../marketplace.css');

.root {
}

.innerHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.viewProfileButton {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textLight);
  display: inline-block;
  font-size: 14px;
  line-height: 1.71;
  min-height: 24px;
  padding: 8px 16px;
  width: auto;

  &:hover {
    background-color: transparent;
    border-color: var(--textDark);
    color: var(--textDark);
  }
}
