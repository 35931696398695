@import '../../marketplace.css';

.root {
  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--white);

  @media (--viewportSmallMax) {
    padding-bottom: 25px;
  }

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  display: block;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportSmallMax) {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;

  @media (--viewportSmallMax) {
    margin-bottom: 0;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 40px;

  @media (--viewportSmallMax) {
    height: 24px;
    text-align: center;
  }
}

.organizationInfo {
  padding-left: 45px;
  width: 200px;

  @media (--viewportSmallMax) {
    display: none;
  }
}

.organizationDescription {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  margin-top: 32px;
}

.copyrightLink {
  color: var(--textLight);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.service {
  display: none;
  flex-basis: 180px;
  margin-right: 24px;
  margin-bottom: 24px;
  order: 2;

  @media (--viewportMedium) {
    display: block;
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.company {
  display: none;
  flex-basis: 165px;
  margin-bottom: 24px;
  margin-right: 24px;
  order: 3;

  @media (--viewportMedium) {
    display: block;
    margin-right: 12px;
    padding-top: 2px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.limitedExtraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }

  & a {
    margin-right: 15px;
  }
}

.legalMatters {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportSmallMax) {
    font-size: 14px;
    margin-right: 18px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.legalLink {
  line-height: 1.71;
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  background-color: var(--matterColorLight);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-size: 14px;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;

  @media (--viewportSmallMax) {
    margin-right: 18px;
  }
}

.someLinks {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.socialMediaLink {
  margin-left: 24px;

  &:first-child {
    margin-left: 0;
  }
}

.logoLink:hover,
.logoLink:active {
  text-decoration: none;
}
.slogan {
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  display: block;
  line-height: 1.29;
  margin: 5px 0 48px 52px;

  @media (--viewportSmallMax) {
    display: none;
  }
}
.copyright {
  color: var(--textLight);
  display: block;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  margin-top: 24px;
}

.header {
  color: var(--textDark);
  font-size: 11px;
  font-weight: var(--fontWeightBold);
  line-height: 1.18;
  letter-spacing: 2px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.mobileLink {
  margin: 0;
}
