@import url('../../../../marketplace.css');

.image {
  display: block;
  margin: 68px auto 0;

  @media (--viewportMedium) {
    margin: 107px auto 0;
  }
}
