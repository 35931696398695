.root {
  padding: 16px;
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20stroke%3D%22%231C1A34%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-linecap%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22M0.75%203.75L15.25%203.75%22%20transform%3D%22translate%28-1232%20-205%29%20translate%28492%20169%29%20translate%28740%2036%29%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M5%202.25c0-.828.806-1.5%201.8-1.5h2.4c.994%200%201.8.672%201.8%201.5%22%20transform%3D%22translate%28-1232%20-205%29%20translate%28492%20169%29%20translate%28740%2036%29%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M8%206L8%2013M10.5%206L10%2013M5.5%206L6%2013%22%20transform%3D%22translate%28-1232%20-205%29%20translate%28492%20169%29%20translate%28740%2036%29%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M13.5%203.25l-1.04%2010.203c-.103%201.02-.963%201.797-1.989%201.797H5.53c-1.026%200-1.886-.777-1.99-1.797L2.5%203.25%22%20transform%3D%22translate%28-1232%20-205%29%20translate%28492%20169%29%20translate%28740%2036%29%22%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
  background-position: center;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 0;
}
