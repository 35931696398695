@import '../../../../marketplace.css';

:root {
  --SectionHero_desktopTitleMaxWidth: 825px;
  --SectionHero_desktopSubTitleMaxWidth: 600px;
}

.root {
  background: url('hero-people-mobile.png') center -10px no-repeat;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 760px;
  padding: 0 24px 10px 24px;
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    background: url('hero-people.png') center center no-repeat;
    max-width: 2000px;
    padding: 0;
  }
}
.title {
  font-weight: var(--fontWeightMedium);
  font-size: 32px;
  letter-spacing: -0.75px;
  line-height: 1.13;
  margin: 385px 0 15px 0;
  max-width: 840px;

  @media (--viewportMedium) {
    font-size: 64px;
    letter-spacing: -2px;
    margin: 83px auto 27px auto;
    text-align: center;
  }
}
.text {
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 1.33;
  margin: 0 0 17px 0;

  @media (--viewportMedium) {
    font-size: 24px;
    margin: 0 auto 33px auto;
    max-width: 781px;
    text-align: center;
  }
}
.buttonContainer {
  text-align: center;
}
.button {
  @apply --marketplaceButtonStyles;
  border-radius: 4px;
  color: var(--textDark);
  display: inline-block;
  line-height: 65px;
  margin: 0 auto !important;
  padding: 0 80px;
  vertical-align: middle;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
  }
}
