@import url('../../../../marketplace.css');

.root {
  display: flex;
  flex-direction: column;
  padding: 0 26px;

  @media (--viewportLarge) {
    margin: 80px auto 0 auto;
    max-width: 1062px;
    padding: 0;
  }
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 30px;
  }
}

.title {
  font-weight: var(--fontWeightMedium);
  font-size: 32px;
  letter-spacing: -0.75px;
  line-height: 1.13;

  @media (--viewportMedium) {
    font-size: 48px;
    letter-spacing: -1px;
    line-height: 1.17;
  }
}

.browseAllLink {
  color: var(--marketplaceColor);
  display: none;
  flex-grow: 1;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  text-align: right;

  @media (--viewportMedium) {
    display: block;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (--viewportSmallMax) {
    flex-direction: column;
  }
}

.mobileActions {
  @media (--viewportMedium) {
    display: none;
  }
}

.browseAllMobileLink {
  background: var(--white);
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
  color: var(--textDark);
  display: block;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 63px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
