@import '../../marketplace.css';

.root {
  background-color: var(--matterColorBright);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media (--viewportMedium) {
    margin: 0 auto;
    min-width: 768px;
    max-width: 904px;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
    max-width: 1440px;
    //width: 1440px;
  }

  @media (--viewportXLarge) {
    max-width: 1920px;
    width: 1920px;
  }
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchResultSummary {
  color: var(--textLight);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 41px;
  vertical-align: middle;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
