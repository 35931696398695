@import '../../marketplace.css';

:root {
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: calc((100% - 1056px) / 2);
  padding-left: calc((100% - 1056px) / 2);
}

.step {
  display: none;

  @media (--viewportMedium) {
    display: inline;
    flex: 1 0 auto;
  }
}

.submitButton {
  color: var(--textDark);
  font-weight: var(--fontWeightMedium);
  padding: 0 30px;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
    flex: 0 1 auto;
    margin-left: 30px;
  }
}
