@import url(../../marketplace.css);

.root {
}

.sideNav {
  background-color: var(--backgroundColor);
  position: relative;
  z-index: 2;
}

.mainContent {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.buttonBack {
  padding-left: 0;
  text-align: left;
}

.loader {
  margin: 24px 0;
}

.modal {
}

.modalHeader {
  margin: 0 0 24px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: #1c1a34;
}

.modalCopy {
  margin: 24px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #1c1a34;
}
.modalBtnContatiner {
  display: flex;
}
.modalCancel {
  padding-left: 0;
  background: #fff;
  border: 1px solid #e6e6e6;
}
.modalCancel:hover {
  background: #fff;
}
.modalContinue {
  padding-left: 0;
  margin-left: 16px;
}

.formControls {
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  padding: 24px 0;
}

.formControls > *:first-child {
  background: transparent;
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
  color: var(--textDark);
  margin-right: 12px;
}
.formControls > *:last-child {
  border-radius: 4px;
  margin-left: 12px;
}

.grayHeader {
  color: var(--textLight);
  font-size: 20px;
  line-height: 1.5;
  font-weight: var(--fontWeightBold);
}

.headerAndButtonContainer,
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createBtn,
.downloadBtn {
  margin-right: 14px;
}

.downloadBtn {
  background-color: #fff;
  border: 1px solid #e6e6e6;
}

.downloadBtn:hover,
.downloadBtn:focus {
  background-color: #fff;
}

.downloadBtn:active {
  background-color: #e6e6e6;
}

.menuLabel {
  display: block;
  margin: 0;
  background-color: #fff;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  color: #1c1a34;
  font-size: 14px;
  line-height: 1.29;
  min-height: unset;
  padding: 12px 40px 12px 16px;
  border: 1px solid #e6e6e6;
  background-image: var(--iconDownArrow);
  background-position: calc(100% - 16px) center !important;
  font-weight: 500;
  min-width: 120px;
}

.menuLabel:hover {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
}

.menuLabel.menuIsOpen:hover,
.menuLabel.menuIsOpen {
  background-color: #e6e6e6;
}

.menuContent {
  min-width: 160px !important;
  padding: 12px 0;
}

.menuItem {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #fac51d;
}

.switcher {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}

.switcher span {
  display: inline-block;
  margin-right: 20px;
  padding-bottom: 20px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.switcher .active {
  color: #fac51d;
  border-bottom: 3px solid #fac51d;
}

.pageOf {
  font-size: 16px;
  line-height: 1.5;
  color: #1c1a34;
}

.next {
  margin: 0 15px 0 10px;
}

.arrowIcon {
  fill: #b2b2b2;
  stroke: #b2b2b2;
}

.arrowIconActive {
  cursor: pointer;
  fill: #fac51d;
  stroke: #fac51d;
}

.paginatorAndbuttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginatorAndbuttonContainer .downloadBtn {
  margin-right: 0;
  width: auto;
}

.sortDesc,
.sortAsc {
  margin-left: 12px;
  cursor: pointer;
  padding: 21px 20px 21px 20px;
  border: 1px solid #e6e6e6;
  transition: 0.3s box-shadow;
  border-radius: 2px;
  background-image: var(--iconSortDown);
  background-position: center;
}

.sortDesc:hover,
.sortAsc:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
}

.sortAsc {
  background-image: var(--iconSortUp);
}

.imageInstructionPreview {
  max-width: 100%;
}

.modalFloatClose {
  /* display: inline-flex;
  align-items: center; */
  float: right;
  margin-bottom: 12px;
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1px;
}

.imageSelectModal > div > div {
  padding: 0 !important;
  border-radius: 6px !important;
  overflow: hidden;
}

.imageSelectModalHeader {
  background-color: #fac51d;
  padding: 21px 24px;
}

.imageSelectModalContent {
  display: flex;
  border-bottom: 1px solid #e6e6e6;
}

.imageSelectModalTabs {
  width: 180px;
  display: flex;
  flex-direction: column;
  flex: 0 0 180px;
  border-right: 1px solid #e6e6e6;
  padding-top: 23px;
  padding-bottom: 32px;
}

.imageSelectModalMain {
  background: #fcfcfc;
  padding: 32px;
  padding-bottom: 60px;
  display: flex;
  flex: 100%;
  position: relative;
}

.uploadedImageImg {
  width: 100%;
}

.imageSelectModalTabItem {
  font-size: 18px;
  padding: 0 32px;
  cursor: pointer;
  line-height: 28px;
  margin-bottom: 14px;
}

.imageSelectModalTabActiveItem {
  font-size: 18px;
  padding: 0 32px 0 28px;
  cursor: pointer;
  line-height: 28px;
  border-left: 4px solid #fac51d;
  margin-bottom: 14px;
}

.uploader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
.uploader .label {
  width: 460px;
  height: 250px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  background: #fcfcfc;
  border: 2px dashed #e6e6e6;
  border-radius: 2px;
  color: #1c1a34;
  cursor: pointer;
}

.uploader .labelImage {
  position: relative;
  width: 460px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploader .replaceImg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  background-color: #fcfcfc;
  color: transparent;
  background-image: var(--iconTrash);
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  border-radius: 3px;
  position: absolute;
  right: 5px;
  width: 30px;
  height: 30px;
  bottom: 5px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: 1px solid #e6e6e6;
}

.uploader .label .title {
  font-size: 20px;
  color: #1c1a34;
  font-weight: 400;
  margin-bottom: 20px;
}

.uploader .label .copy {
  font-size: 16px;
  color: #b2b2b2;
  font-weight: 400;
  line-height: 1.2;
}

.uploader .input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  padding: 0;
}

.cancelSelect {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: center;
  background: #fff;
  border-top: 1px solid #e6e6e6;
}

.cancelSelect > span {
  padding: 12px;
  cursor: pointer;
  color: #b2b2b2;
}

.cancelSelect > span + span {
  margin-right: 12px;
  color: #e5b700;
}

.featuredImages {
  display: flex;
  flex-wrap: wrap;
}

.featuredImages img {
  width: 160px;
  height: 100px;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 2px;
}
@media (max-width: 440px) {
  .featuredImages img {
    width: 120px;
    height: 60px;
  }
}

.featuredImg {
  border: 2px solid transparent;
}

.selectedFeaturedImg {
  padding: 4px;
  border: 2px solid #fac51d;
}

@media (min-width: 760px) {
  .imageSelectModal > div > div {
    flex-basis: 100% !important;
  }
}

@media (min-width: 1024px) {
  .imageSelectModal > div > div {
    flex-basis: 860px !important;
  }
}

@media (max-width: 760px) {
  .imageSelectModalContent {
    flex-direction: column !important;
  }
  .imageSelectModalTabs {
    border-right: 0;
    flex-direction: row;
    border-bottom: 1px solid #e6e6e6;
    flex: 0 0 100%;
    width: 100%;
    padding: 20px 10px;
  }
  .imageSelectModalTabItem {
    line-height: 20px;
    font-size: 16px;
    padding: 0 10px;
    border-left: none;
    margin-bottom: 0;
  }

  .imageSelectModalTabActiveItem {
    line-height: 20px;
    font-size: 16px;
    padding: 0 10px;
    border-left: none;
    margin-bottom: 0;
    color: #fac51d;
  }
}

.unsplashContainer {
  display: flex;
  flex-direction: column;
}

.searchUnsplashWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.unsplashResultsWrapper {
  column-count: 4;
  column-gap: 10px;
}

.unsplashResultsWrapper img {
  grid-row: 1 / -1;
  grid-column: 1;
  max-width: 100%;
  display: block;
}

.unsplashResultsWrapper figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

.unsplashResultsWrapper figure a {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

.unsplashResultsWrapper figure a:hover {
  text-decoration: underline;
}

.unsplashResultsWrapper figcaption {
  display: none;
}

.unsplashResultsWrapper figure:hover figcaption,
.activeUnsplashImage + figcaption {
  display: block;
}

.unsplashResultsWrapper figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 2px;
  padding: 0 4px;
}

.activeUnsplashImage + figcaption {
  margin: 4px;
  padding: 0 6px;
}

.imageWarn {
  color: red;
  font-size: 16px;
  margin-bottom: 0;
}

.unsplashImage {
  border: 2px solid transparent;
  cursor: pointer;
}
.activeUnsplashImage {
  border: 2px solid #fac51d;
  padding: 2px;
  cursor: pointer;
}

.imagesByUnsplash {
  flex: 0 0 170px;
  text-align: right;
  font-size: 14px;
  color: #b2b2b2;
}

.searchUnsplashWrapper input {
  flex: 0 1 400px;
}

.unsplashInput {
  padding-left: 32px;
  background-position: left 40%;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='matrix(-1 0 0 1 20 1)' stroke='%23fac51d' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M13 14l5.241 5.241'%3E%3C/path%3E%3Ccircle cx='7.5' cy='7.5' r='7.5'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
}

.outreachDetailRequestDownloadButton {
  margin: 0 0 16px auto;
}
