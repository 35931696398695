@import '../../marketplace.css';

:root {
}

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.topics {
  margin-bottom: 32px;
}

.additionalTopic {
  display: flex;
  flex-direction: row;
}

.addTopicButton,
.addTopicButton:active,
.addTopicButton:focus {
  background: transparent
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="black" fill-rule="evenodd" d="M6 0a1 1 0 0 1 1 1v4h4a1 1 0 0 1 0 2H7v4a1 1 0 0 1-2 0V7H1a1 1 0 1 1 0-2h4V1a1 1 0 0 1 1-1z"/></svg>')
    14px 13px no-repeat !important;
  border: 1px solid var(--matterColorNegative) !important;
  color: var(--textLight) !important;
  display: inline-block !important;
  font-size: 14px !important;
  margin-bottom: 80px !important;
  min-height: 40px !important;
  padding: 0 12px 0 40px !important;
  width: auto !important;

  &:hover {
    background: transparent
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="black" fill-rule="evenodd" d="M6 0a1 1 0 0 1 1 1v4h4a1 1 0 0 1 0 2H7v4a1 1 0 0 1-2 0V7H1a1 1 0 1 1 0-2h4V1a1 1 0 0 1 1-1z"/></svg>')
      14px 13px no-repeat;
    border-color: var(--matterColorDark);
    color: var(--matterColorDark);
  }
}

.additionalTopicField {
  flex-grow: 1;
  margin-bottom: 24px;
}

.deleteButton,
.deleteButton:hover,
.deleteButton:active,
.deleteButton:focus {
  background: transparent
    url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%231C1A34%22%3E%3Cpath%20stroke-linecap%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22M.75%203.75h14.5%22%2F%3E%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M5%202.25c0-.828.806-1.5%201.8-1.5h2.4c.994%200%201.8.672%201.8%201.5%22%2F%3E%3Cpath%20stroke-linecap%3D%22round%22%20d%3D%22M8%206v7M10.5%206l-.5%207M5.5%206l.5%207%22%2F%3E%3Cpath%20stroke-width%3D%221.5%22%20d%3D%22M13.5%203.25l-1.04%2010.203a2%202%200%200%201-1.989%201.797H5.53a2%202%200%200%201-1.99-1.797L2.5%203.25%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')
    left top no-repeat !important;
  border: none !important;
  box-shadow: none !important;
  color: transparent !important;
  display: inline-block !important;
  height: 16px !important;
  line-height: 16px !important;
  margin: 30px 0 0 -16px !important;
  min-height: 16px !important;
  padding: 0 !important;
  width: 16px !important;

  @media (--viewportMedium) {
    margin: 40px 0 0 -16px;
  }
}

.additionalTopic:first-child .deleteButton {
  display: none !important;
}
