@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.leadText {
  @apply --marketplaceMessageFontStyles;
  font-family: GTWalsheimPro;
  line-height: 1.5;
  color: #1c1a34;
  margin: 0;
  margin-bottom: 24px;
}

.name {
  margin-top: 24px;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
}

.firstNameRoot {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin: 0;
    width: calc(34% - 9px);
  }
}

.lastNameRoot {
  @media (--viewportMedium) {
    width: calc(66% - 9px);
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 32px;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkboxes {
  padding-top: 32px;
}

.footNote {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
  text-align: center;
  color: var(--matterColorAnti);
}

.email {
  font-size: 14px;
  font-family: 'GTWalsheimPro', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0;
  margin: 4px 0;
}

.email-ok {
  color: #2ecc71;
}

.email-notfound {
  color: #ff0000;
}
.password-validator-list {
  list-style: none;
  margin: 8px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.password-validator-list h4 {
  margin-bottom: 0;
  font-size: 14px;
}
.password-validator-list li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
}
