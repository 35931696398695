@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: auto;
  padding-top: 24px;
  width: 100%;
}

.imagesField {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    border-right: 12px solid white;
    border-bottom: 24px solid white;

    &:nth-child(even) {
      border-left: 12px solid white;
      border-right: none;
      margin: 0 0 0 0;
    }
  }
}

.addImageWrapper {
  display: inline-block;
  width: 50%;

  padding-left: 0;
  padding-right: 12px;
  &:nth-child(even) {
    padding-left: 12px;
    padding-right: 0;
  }

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    //width: var(--EditListingPhotosForm_imageWidthDesktop);
    //margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  //padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  align-items: center;
  display: flex;
  height: 245px;
  justify-content: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--backgroundColorAlt);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-size: 18px;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  font-size: 18px;
  padding-top: 30px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  color: var(--textDark);
  margin-top: auto;
  margin-bottom: 24px;
  padding: 0 30px;

  @media (--viewportLarge) {
    display: inline-block;
    margin-top: 100px;
    padding: 0 60px;
    width: auto;
  }
}
