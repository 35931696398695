@import url('../../marketplace.css');

.root {
  border-bottom: 1px solid var(--matterColorNegative);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 23px 0 18px 0;
  position: relative;
  transition: 300ms;

  @media (--viewportMedium) {
    transition: unset;
  }
}

.profileImage {
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  margin-right: 38px;
  position: relative;
  width: 40px;

  & svg {
    height: 40px;
    width: 40px;
  }
}

.messageContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.message {
  color: var(--textDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.25;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 1.11;
  }
}

.name {
  font-weight: var(--fontWeightBold);
}

.dateTime {
  color: var(--textLight);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
}

.new .profileImage::after {
  background-color: var(--failColor);
  border-radius: 5px;
  content: ' ';
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  right: -26px;
  top: 4px;
}

.controls {
}

.archive {
  background: var(--iconTrash) center center no-repeat;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.optionsOpen {
  @media (--viewportSmallMax) {
    margin-left: -143px;
    padding-right: 143px;
    width: calc(100vw + 120px);

    & .rowControls {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      & button {
        padding: 0 15px;
        margin-right: 0;
        width: 80px;
      }
    }
  }
}
