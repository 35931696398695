@import '../../marketplace.css';

.root {
  margin-top: 24px;
  max-width: calc(100vw - 48px);
  width: 100%;

  @media (--viewportSmallMax) {
    padding-top: 48px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--white);
  font-size: 48px;
  font-weight: var(--fontWeightMedium);
  margin-top: 0;
  margin-bottom: 50px;

  @media (--viewportSmallMax) {
    font-size: 32px;
    line-height: 1.75;
    letter-spacing: -0.67px;
    margin-bottom: 0;
  }
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportSmallMax) {
    padding-bottom: 45px;
  }

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  color: var(--white);

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }

  @media (--viewportLarge) {
    min-height: 380px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepNumber {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  margin: 0;

  @media (--viewportSmallMax) {
    font-size: 48px;
    font-weight: var(--fontWeightMedium);
    line-height: 0.88;
    letter-spacing: -0.6px;
    padding: 32px 0 12px 0;
  }
}

.stepTitle {
  margin-top: 0;
}

.stepText {
  font-size: 16px;
  line-height: 1.5;
  font-weight: var(--fontWeightRegular);
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}

.buttonSignUp {
  color: var(--textDark);
  border-radius: 4px;
  margin-top: 80px;

  @media (--viewportSmallMax) {
    display: none;
  }
}

.buttonSignUpMobile {
  border-radius: 4px;
  color: var(--textDark);
  margin-top: 72px;

  @media (--viewportMedium) {
    display: none;
  }
}
