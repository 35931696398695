@import '../../marketplace.css';

.pageTitle {
  text-align: left;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 840px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentMain {
  width: 100%;
}

.description {
  font-weight: var(--fontWeightRegular);
}

.section {
}

.sectionHeader {
  @apply --marketplaceH3FontStyles;
  color: var(--textLight);
  font-weight: var(--fontWeightBold);
  padding-bottom: 19px;
  margin-bottom: 0;
  margin-top: 48px;
}

.faqList {
  border-top: 1px solid var(--matterColorNegative);
}

.faqItem {
  border-bottom: 1px solid var(--matterColorNegative);
  padding: 18px 0;
}

.faqItemButton {
  background: url('data:image/svg+xml;utf8,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2210%22%20viewBox%3D%220%200%2016%2010%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%231C1A34%22%20fill-rule%3D%22nonzero%22%20d%3D%22M.977.782L.875.875c-.5.498-.5%201.306%200%201.807l6.222%206.222c.5.498%201.306.498%201.806%200l6.222-6.222c.5-.5.5-1.309%200-1.807L15.021.78a1.275%201.275%200%200%200-1.703.095L8%206.193%202.681.875A1.276%201.276%200%200%200%20.977.782z%22%2F%3E%0A%3C%2Fsvg%3E%0A')
    right 6px no-repeat;
  cursor: pointer !important;
  display: block;
  font-size: 20px;
  outline: none;
  padding-right: 8%;
}
.faqItemButton[aria-expanded='true'] {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2210%22%20viewBox%3D%220%200%2016%2010%22%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%231C1A34%22%20fill-rule%3D%22nonzero%22%20d%3D%22M15.023%208.773l.102-.092c.5-.499.5-1.307%200-1.807L8.904.652A1.279%201.279%200%200%200%207.097.65L.874%206.874c-.499.5-.499%201.308%200%201.807l.105.093a1.275%201.275%200%200%200%201.703-.094L8%203.363l5.319%205.318a1.276%201.276%200%200%200%201.704.092z%22%2F%3E%0A%3C%2Fsvg%3E%0A');
}

.faqItemBody {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  padding-top: 9px;
  width: 92%;
  white-space: pre-line;
}

.footNotes {
  margin-top: 48px;
}

.footNote {
  font-weight: var(--fontWeightRegular);
}

.highlight {
  font-style: italic;
}
