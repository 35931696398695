@import url('../../marketplace.css');

.root {
  padding: 0 24px;
  margin: 0 auto;
  max-width: 600px;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 0 36px;
    width: 100%;
  }

  @media (--viewportLarge) {
    max-width: 1056px;
    padding: 0 calc((100vw / 1056) * 12);
  }

  @media (--viewportDesktopMedium) {
    padding: 0;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  }
}
.headerText {
  color: var(--textDark);
  font-size: 32px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.13;
  letter-spacing: -0.75px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 1.17;
    letter-spacing: -1px;
  }
}
.headerBrowse {
  background: transparent;
  color: var(--marketplaceColorDark);
  display: none;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  height: auto;
  min-height: unset;
  position: relative;
  text-align: right;
  width: auto;
  z-index: 2;

  @media (--viewportMedium) {
    cursor: pointer;
    display: inline-block;
  }

  &:hover,
  &:active {
    background: transparent;
    box-shadow: none;
  }
}
.footerBrowse {
  background: var(--white);
  border-radius: 4px;
  border: solid 1px var(--matterColorNegative);
  color: var(--textDark);
  display: inline-block;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 64px;
  margin: 24px 0;
  vertical-align: middle;

  @media (--viewportMedium) {
    display: none;
  }
}

.locations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  position: relative;
  z-index: 2;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
  }
}

.location {
  background: var(--marketplaceColor);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  flex-basis: 0;
  margin: 0 11px 11px 0;
  min-height: 158px;
  min-width: 158px;
  max-height: 192px;
  max-width: 192px;

  &:hover {
    box-shadow: var(--boxShadowListingCard);
  }

  @media (--viewportMedium) {
    margin: 0 0 80px 0;
    max-height: 158px;
    max-width: 158px;

    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }
  }

  @media (--viewportLarge) {
    height: 192px;
    width: 192px;
    min-width: 192px;
    max-height: 192px;
    max-width: 192px;

    &:nth-child(4),
    &:nth-child(5) {
      display: flex;
    }
    &:nth-child(6) {
      display: none;
    }
  }
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
  color: var(--textDark);
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  line-height: 1.43;
  padding: 0 16px;
}

.subTitle {
  color: var(--white);
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.29;
  padding: 0 16px 12px 16px;
}

.image {
  min-width: 158px;
  max-width: 192px;
}
