@import url('../../marketplace.css');

.root {
  display: inline-block;
}

.menuButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  height: auto;
  width: auto;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.menuContent {
  width: 100vw;
  position: fixed;
  top: 0px;
  height: 100%;
  left: 0;
  z-index: 200;
  background-color: var(--backgroundColor);
}

.menuItems {
  margin-top: 0;
  min-width: 320px;
}

.featuredLocations {
  padding-left: 35px;
}

.input {
  background: var(--iconMarker);
  background-position-x: 26px;
  border: 0;

  line-height: 64px;
  min-height: 64px;
  margin-bottom: 8px;
  padding: 0 0 0 57px;
  vertical-align: middle;
}

.icon {
  display: none;
}

.topLocations {
  color: var(--matterColorAnti);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 64px;
  min-height: 64px;
  padding: 0 0 0 35px;
  vertical-align: middle;
}

.predictions {
  margin: 0;
  padding: 15px 0 0 35px;
}

.prediction {
  list-style-type: none;
}

.searchLink {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
}

.searchLink strong {
  font-weight: var(--fontWeightMedium);
}

.place {
  color: var(--textDark);
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 2.22;
}

.region {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
}

.noResults {
  padding: 35px 35px 10px 35px;
}

.isLoading {
  padding: 35px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;

  outline: 0;
  border: 0;

  @apply --marketplaceModalCloseText;
}

.formHeader {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  padding-top: 60px;
}
