@import '../../marketplace.css';

.layoutWrapper {
  padding-top: 29px;

  @media (--viewportLarge) {
    padding-top: 50px;
  }
}

.content {
  overflow-x: hidden;

  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    overflow: hidden;
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

/*.title {*/
/*  font-weight: var(--fontWeightMedium);*/
/*  hyphens: auto;*/
/*  margin-top: 8px;*/
/*  margin-bottom: 19px;*/

/*  @media (--viewportMedium) {*/
/*    font-weight: var(--fontWeightBold);*/
/*    margin-bottom: 47px;*/
/*  }*/
/*}*/

.back {
  background: var(--iconArrowLeft);
  background-position: left center;
  border: 0;
  color: var(--marketplaceColor);
  cursor: pointer;
  display: inline-block;
  padding-left: 20px;
  position: relative;
  left: 0;
  top: 0;
  height: 41px;
  z-index: calc(var(--zIndexTopbar) - 1);

  &:focus {
    outline: none;
  }
}

.sidePanel {
  & .title {
    margin-top: 50px;
  }
}

.mainPanel {
  @media (--viewportLarge) {
    padding-top: 80px;
  }
}

.savedCredit {
  margin-top: 40px;

  @media (--viewportLarge) {
    margin-top: 60px;
  }
}
