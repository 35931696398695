@import '../../marketplace.css';

.root {
}

.card {
  align-items: center;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  margin-bottom: 18px;
  width: 100%;

  @media (--viewportMedium) {
    border: 2px solid var(--matterColorNegative);
    width: auto;
    flex-direction: row;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.logoWrapper {
  width: 100%;
  /** bottom space done with padding on .content */
  padding: 24px 24px 0 24px;
  @media (--viewportMedium) {
    width: auto;
  }
}

.logo {
  background: transparent !important;
  border: 1px solid rgba(28, 26, 52, 0.1);
  display: inline-block;
}

.logoLarge {
  height: 96px;
  min-width: 96px;
  width: 96px;
  @media (--viewportSmallMax) {
    display: none !important;
  }
}

.logoSmall {
  height: 44px;
  min-width: 44px;
  width: 44px;

  /** Note: don't really like how this avatar responsiveness is now, but it works */
  @media (--viewportMedium) {
    display: none !important;
  }
}

.content {
  flex-grow: 1;
  padding: 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 20px 0 20px 32px;
  }
}

.heading {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  line-height: 18px;
  margin: 0;
}

.organizationLink {
  display: inline-block;
  margin-left: 8px;
}

.subHeading {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  margin: 0;
}

.description {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
}

.foot {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  line-height: 18px;
}

.selector {
  position: absolute;
  align-self: flex-start;
  border: 0;
  color: var(--matterColorAnti);
  cursor: pointer;
  display: flex;
  align-items: center;

  font-size: 14px;
  right: 24px;
  top: 24px;

  @media (--viewportMedium) {
    margin-top: 15px;
    position: relative;
    top: 0;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.4;
  }
}

.selectorLabel {
  padding-right: 8px;
  color: var(--textDark);
}

.selectedLabel {
  color: var(--marketplaceColorDark);
}
