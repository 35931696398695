@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
}
.content {
  width: 100%;
  padding: 24px;

  @media (--viewportMedium) {
    width: calc(100% - 48px);
    max-width: 756px;
    min-width: 756px;
    margin: 0 auto 56px auto;
    padding: 112px 0 0 0;
  }
}

.headingContainer {
  display: block;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.subHeading {
  font-weight: normal;
}
