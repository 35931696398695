@import url('../../marketplace.css');

.root {
}

.header {
  font-size: 30px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.5px;
  line-height: 1.33;
}

.description {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
}

.field {
  margin-bottom: 32px;
}

.controls {
  display: flex;
  flex-direction: row;

  & button:first-child {
    margin-right: 6px;
  }
  & button:last-child {
    margin-left: 6px;
  }
}
