@import url('../../../../marketplace.css');

.section,
.sectionWithBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .title {
    @apply --marketplaceH1FontStyles;
    font-size: var(--eventTitleSize);
    color: var(--eventTitleColor);
    line-height: var(--eventTitleLineHeight);
    letter-spacing: var(--eventTitleLetterSpacing);
  }

  & .text {
    font-size: var(--eventTextSize);
    color: var(--eventTextColor);
    line-height: var(--eventTextLineHeight);
    letter-spacing: var(--eventTextLetterSpacing);
  }

  & .button {
    margin-top: 45px;
  }

  & .link {
    color: var(--eventPrimaryColor);
    margin-top: 35px;

    &:hover {
      color: var(--eventPrimaryColorDarker);
    }
  }
}

.section {
  margin: 0 auto 178px;
  padding: 0 32px;
  max-width: 624px;
  text-align: center;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.sectionWithBackground {
  @apply --eventLandingPagePaddings;
  padding-top: 400px;
  padding-bottom: 178px;
  max-width: unset;

  color: var(--white);
}

.externalLinkIcon {
  fill: var(--eventPrimaryColor);
}

.background {
  margin: -380px auto 0;
  height: 1060px;
}
