@import url('../../marketplace.css');

.description {
  color: var(--textDark);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  margin-bottom: 0;
}
.calendarIntegration {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}
.urlField {
  background: var(--marketplaceColorLightest);
  border-radius: 2px;
  border-bottom: 0;
  color: var(--textDark);
  flex-grow: 1;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
  max-height: 42px;
  margin-right: 8px;
  overflow: hidden;
  padding: 8px 12px;
  width: 100%;
}
.copyButton {
  background: var(--white);
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
  color: var(--textDark);
  flex-shrink: 1;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
  min-height: unset;
  min-width: 85px;
  max-height: 42px;
  width: auto;

  &:hover,
  &:active,
  &:focus {
    background: var(--white);
    box-shadow: none;
  }
}
.copyButtonCopied {
  background-color: var(--marketplaceColor);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--marketplaceColor);
  }
}
.instructions {
  margin: 0;
  padding: 0;
}
.instruction {
  color: var(--marketplaceColorDark);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 1.5;
  list-style-type: none;
}
.externalLink {
  background: var(--iconExternalLink);
  display: inline-block;
  padding-right: 18px;
}
