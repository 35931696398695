@import url('../../marketplace.css');

.locations {
  margin: 0;
  padding: 0;
}
.location {
  list-style-type: none;
  margin: 0 0 6px 0;
  padding: 0;
}
.link {
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover,
  &:active {
    background: transparent;
    box-shadow: none;
    text-decoration: none;
  }
}
.place {
  color: var(--textDark);
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 2.22;
}
.region {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.71;
}
