@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --DateInput_selectionHeight: 36px;
  --DateInput_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  /* Distance to top */
  --DateInput_top: 252px;
}

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.DayPicker) {
    display: block;
    border: 0;
  }
  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - var(--DateInput_top));
    background-color: var(--marketplaceColor);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    @apply --marketplaceDefaultFontStyles;
    @apply --marketplaceInputStyles;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--attentionColor);
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%234A4A4A" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 0 8px;
    padding-left: 24px;

    @media (--viewportMedium) {
      padding: 4px 0 9px 26px;
      border-bottom-width: 3px;
    }
  }

  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--white);
    box-shadow: none;
  }

  & :global(.DayPicker_transitionContainer) {
    min-height: 340px !important;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 19px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorAnti);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.CalendarMonth) {
    padding: 0 !important;
  }

  & :global(.CalendarMonthGrid__horizontal) {
    left: 0;
  }

  & :global(.CalendarMonthGrid_month__horizontal) {
    min-width: 312px;
  }

  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeaders__horizontal) {
    margin-left: 0;
  }

  & :global(.DayPicker_weekHeader) {
    color: var(--matterColorAnti);
    padding: 0 !important;
    top: 57px;
    width: 312px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    width: 36px;
  }

  & :global(.DayPicker_weekHeader_li small) {
    font-size: 100%;
  }

  & :global(.CalendarMonth_table) {
    min-width: 312px;
    position: relative;
    top: 36px;
  }
  & :global(.CalendarMonth_table tr) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 4px;
  }

  & :global(.CalendarMonth_caption) {
    color: var(--matterColorDark);
    @apply --marketplaceH3FontStyles;
    margin: 1px 0 14px;
    font-weight: 400;
    padding-top: 0;

    @media (--viewportMedium) {
      font-size: 18px;
      font-weight: var(--fontWeightBold);
      line-height: 1.11;
      margin-bottom: 0;
      margin-top: 0;
      text-align: left;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--matterColorLight);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay__default .renderedDay) {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    width: 36px;
  }
  & :global(.CalendarDay) {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorAnti);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 36px;
    width: 36px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__outside) {
    visibility: hidden;
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today) {
  }

  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23e7e7e7' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

  & :global(.CalendarDay__highlighted_calendar) {
  }

  & :global(.CalendarDay__highlighted_calendar .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    color: var(--matterColorDark);
    background-color: var(--marketplaceColorLightest);
    border-radius: 50%;
  }

  & :global(.CalendarDay__highlighted_calendar .renderedDay.eventSlots) {
    background-color: var(--marketplaceColorBlueLightest);
  }

  & :global(.DayPicker_weekHeader_ul) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 312px;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
  }
  /*
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
  }
  */
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--successColor);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }

  & :global(.CalendarDay__selected .renderedDay.eventSlots) {
    background-color: var(--marketplaceColorBlue);
    color: var(--white);
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  /*
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  */
  /*
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
  }
  */
  /*
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
  }
  */
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--matterColorAnti);
    border: 0;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--matterColorAnti);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput__disabled) {
    background: none;
  }
  & :global(.DateInput_input__disabled) {
    @apply --marketplaceDefaultFontStyles;
    @apply --marketplaceInputStyles;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--matterColorNegative);
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%23E6E6E6" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 0 8px;
    padding-left: 24px;

    &:hover {
      border-bottom-color: var(--matterColorNegative);
    }

    @media (--viewportMedium) {
      padding: 4px 0 9px 24px;
      border-bottom-width: 3px;
    }
  }

  & :global(.DateInput_input__disabled::placeholder) {
    color: var(--matterColorNegative);
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    width: calc(100vw - 48px);
    margin: 0 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    border-top: 2px solid var(--matterColorDark);
    min-height: calc(100vh - var(--DateInput_top) + 2px);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}
