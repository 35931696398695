@import url('../../marketplace.css');

.root {
  @media (--viewportMedium) {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='2040' height='1220'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h2040v1220H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cellipse cx='1020.1' cy='610' fill='%23FAC51D' mask='url(%23b)' rx='1040' ry='565' transform='rotate(-15 1020 610)'/%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
  }
}

.wrapper {
  max-width: 1110px;
  margin: 80px auto;
  padding: 0 24px;
}

.valuePropContainer {
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
  justify-content: space-between;
}

.wrapper .valuePropContainer:nth-child(odd) {
  align-items: center;
  @media (--viewportMedium) {
    align-items: flex-start;
  }
}
.wrapper .valuePropContainer:nth-child(even) {
  align-items: center;
  @media (--viewportMedium) {
    align-items: flex-end;
    flex-direction: row-reverse;
  }
}

.wrapper .valuePropContainer:nth-child(2) {
  @media (--viewportMedium) {
    margin-bottom: 80px;
  }
}

.valuePropContainer .texts {
  flex: 0 0 100%;
  order: 2;
  @media (--viewportMedium) {
    flex: 0 2 600px;
    order: -1;
  }
}

.valuePropContainer .image {
  flex: 0 0 100%;
  @media (--viewportMedium) {
    flex: 1 1 477px;
  }
}

.valuePropContainer .texts .step {
  color: #b2b2b2;
  font-size: 20px;
  margin: 18px 0 24px;
  line-height: 1.2;
  font-weight: bold;
}
.valuePropContainer .texts .title {
  margin: 24px 0;
  line-height: 1.17;
  font-weight: 500;
}
.valuePropContainer .texts .copy {
  font-size: 16px;
  margin: 24px 0;
  line-height: 1.5;
  font-weight: 500;
}

.valuePropContainer .texts .cta {
  display: block;
  margin: 24px 0 18px;
}

.valuePropContainer .texts .cta,
.valuePropContainer .texts .cta:after {
  color: #e5b700;
  text-decoration: none;
}

.valuePropContainer .texts .cta:after {
  content: '';
  width: 22px;
  height: 14px;
  display: inline-block;
  background: var(--iconArrowRight);
}

.valuePropContainer:nth-child(2) .texts .cta:after,
.valuePropContainer:nth-child(3) .texts .cta:after {
  @media (--viewportMedium) {
    filter: brightness(9);
  }
}

.valuePropContainer:nth-child(2) .texts .step,
.valuePropContainer:nth-child(2) .texts .cta,
.valuePropContainer:nth-child(2) .texts .cta:after,
.valuePropContainer:nth-child(3) .texts .step,
.valuePropContainer:nth-child(3) .texts .cta,
.valuePropContainer:nth-child(3) .texts .cta:after {
  @media (--viewportMedium) {
    color: #fff;
  }
}

.valuePropContainer .image img {
  width: 100%;
  vertical-align: middle;
}
