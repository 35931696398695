@import '../../marketplace.css';

.bg-meeting-sunset {
  flex: 1;
  @apply --backgroundMeetingSunset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-meeting-sunset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px;
  gap: 32px;
  width: 100%;
  height: 100%;
  max-width: 840px;
  margin: 16px;

  background: #ffffff;

  /* Shadow/Card Shadow 200 */
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  & > * {
    margin: 0;
  }

  @media (--viewportSmallMax) {
    padding: 40px;
    gap: 24px;
  }
}

.link-meeting-sunset button {
  padding: 16px 32px;
}

.small-meeting-sunset {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* or 129% */
  font-feature-settings: 'liga' off;

  /* Background/Grey 400 */
  color: #b2b2b2;

  & a {
    font-weight: bold;
  }
}

.profile-strip {
  display: flex;
  align-items: center;
  gap: 16px;

  &img {
    object-fit: cover;
  }

  & div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & span:first-child {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;

      /* or 160% */
      letter-spacing: -0.1px;
      font-feature-settings: 'liga' off;

      /* Brand / Navy */
      color: #1c1a34;
    }

    & span:last-child {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      /* identical to box height, or 150% */
      font-feature-settings: 'liga' off;

      /* Brand / Navy */
      color: #1c1a34;
    }
  }
}

.connect-btn button {
  padding: 16px 32px;
}
