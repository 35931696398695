@import url('../../marketplace.css');

.root {
  border-radius: 8px;
  margin-bottom: 32px;
  padding: 24px;
  position: relative;
}

.regular {
  background-color: #f8e190;
  color: var(--textDark);
}
.success {
  background-color: #b0e3b6;
  color: var(--textDark);
}

.failure {
  background-color: #ebc7c2;
  color: var(--textDark);
}

.info {
  background-color: #abd3ff;
  color: var(--textDark);
}

.header {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
.body {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.dismiss {
  background: var(--iconX) center center no-repeat;
  background-size: contain;
  border: 0;
  cursor: pointer;
  height: 12px;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 12px;

  &:focus {
    outline: none;
  }
}
