@import '../../marketplace.css';

:root {
}

.bottomBarContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: var(--white);
  z-index: 1;
  box-shadow: var(--boxShadowTop);
  padding: 18px;
  transition: var(--transitionStyle);

  &.hiddenBottomBar {
    height: 0;
    bottom: calc(-2 * 18px - 2px); /* Size of padding and box shadow */
  }
}
